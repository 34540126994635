import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Avatar,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { EmployeeForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUpload } from '../../components/CSVUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  firstname: '',
  lastname: '',
  department: '',
  role: '',
  username: '',
  password: '',
  phone_number: '',
  addedEmployeeImage: '',
};

function Employees({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const roletype = useSelector((state) => state.roletype);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [addedEmployeeImage, setAddedEmployeeImage] = useState([]);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  console.log('department', department);
  console.log('roletype', roletype);

  // console.log('addedEmployeeImage', addedEmployeeImage);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(actions.employeeAll({ name, page, size }));
    dispatch(actions.departmentAll(''));
    dispatch(actions.roletypeAll(''));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(employee?.total);
    return () => {};
  }, [employee]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign
      const payload = {
        ...data,
        image: addedEmployeeImage[0]?.data_url,
      };
      console.log('payload', payload);
      if (addUser === true) {
        if (data.password === data.confirmPassword) {
          await dispatch(actions.userRegister(payload));
          reset(defaultValue);
          alert('สำเร็จ');
          handleClose();
          setAddUser(false);
          await dispatch(actions.employeeAll({ name, page, size }));
        } else {
          alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
        }
      } else {
        await dispatch(actions.employeeCreate(payload));
        reset(defaultValue);
        alert('สำเร็จ');
        handleClose();
        setAddUser(false);
        await dispatch(actions.employeeAll({ name, page, size }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteEmployee = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.employeeDelete(id));
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditEmployee = (id) => {
    history.push(`/hrms/employee/edit/${id}`);
  };

  const handlePushToEditUser = (id) => {
    history.push(`/hrms/user/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.employeeCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มพนักงานไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        เพิ่ม
      </Button>
      <Button
        variant="contained"
        onClick={handleCSVOpen}
        startIcon={<FileUploadIcon />}
      >
        อัพโหลด
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">เพิ่มข้อมูลพนักงาน</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <EmployeeForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  employee={employee}
                  department={department}
                  roletype={roletype}
                  addUser={addUser}
                  setAddUser={setAddUser}
                  employeeImage={addedEmployeeImage}
                  setEmployeeImage={setAddedEmployeeImage}
                />
                <div className="flex flex-row justify-end gap-1 py-8 mb-10">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="pt-2 text-xl">อัพโหลดข้อมูล</div>
          <div className="py-2 font-system">
            อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
            และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
            <Button variant="contained">
              <Link to="/filetemplate/Employee.csv" target="_blank">
                ดาวน์โหลดเทมเพลต
              </Link>
            </Button>
          </div>
          <div className="my-2">
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="pb-2">
                * โปรดแทนที่ข้อมูลใน <strong>แผนก</strong> ด้วยรหัสดังต่อไปนี้
              </div>
              <div className="rounded-sm overflow-y-auto">
                <Table
                  sx={{
                    '&:last-child td, &:last-child th': { border: 1 },
                  }}
                >
                  <TableHead>
                    <TableCell>ลำดับ</TableCell>
                    <TableCell>ชื่อแผนก</TableCell>
                    <TableCell>รหัส</TableCell>
                  </TableHead>
                  <TableBody>
                    {_.map(department?.rows, (_department, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1} </TableCell>
                        <TableCell>{_department?.name} </TableCell>
                        <TableCell>{_department?.id} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="pb-2">
                * โปรดแทนที่ข้อมูลใน <strong>บทบาท</strong> ด้วยรหัสดังต่อไปนี้
              </div>
              <div className="rounded-sm overflow-y-auto">
                <Table
                  sx={{
                    '&:last-child td, &:last-child th': { border: 1 },
                  }}
                >
                  <TableHead>
                    <TableCell>ลำดับ</TableCell>
                    <TableCell>ชื่อแผนก</TableCell>
                    <TableCell>รหัส</TableCell>
                  </TableHead>
                  <TableBody>
                    {_.map(roletype?.rows, (_roletype, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1} </TableCell>
                        <TableCell>{_roletype?.name} </TableCell>
                        <TableCell>{_roletype?.id} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex justify-center">
              <div>
                <CSVUpload setCsvData={setCsvData} />
                {!_.isEmpty(csvData) && (
                  <div>
                    <p className="font-system my-1">
                      พบข้อมูล {_.size(csvData)} รายการ
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={handleAddFromFile}
                    >
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  function stringAvatar(EmployeeName) {
    return {
      children: `${EmployeeName.split(' ')[0][0]}${
        EmployeeName.split(' ')[1][0]
      }`,
    };
  }

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ-สกุล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เบอร์โทร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> แผนก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> บทบาท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(employee.rows) ? (
                employee.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="flex">
                        <div className="my-auto pr-2">
                          {row.image ? (
                            <Avatar
                              alt={`${row?.firstname} ${row?.lastname}`}
                              src={row?.image?.url}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(
                                `${row?.firstname} ${row?.lastname}`,
                              )}
                            />
                          )}
                        </div>
                        <div className="my-auto font-bold">{`${row?.firstname} ${row?.lastname}`}</div>
                      </div>
                    </TableCell>
                    <TableCell>{row?.phone_number || '-'}</TableCell>
                    <TableCell>{`${row?.department?.name}`}</TableCell>
                    <TableCell>{`${row?.role?.name}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          size={'small'}
                          // eslint-disable-next-line no-underscore-dangle
                          onClick={() => handlePushToEditUser(row?.uid?._id)}
                          disabled={
                            row?.firstname === 'SUPER' &&
                            row?.lastname === 'USER'
                          }
                        >
                          จัดการผู้ใช้
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditEmployee(row.id)}
                          disabled={
                            row?.firstname === 'SUPER' &&
                            row?.lastname === 'USER'
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteEmployee(row?.id);
                          }}
                          disabled={
                            row?.firstname === 'SUPER' &&
                            row?.lastname === 'USER'
                          }
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderModal()}
        {renderCSVUploadModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Employees.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Employees.defaultProps = {
  title: '',
  subtitle: '',
};

export default Employees;
