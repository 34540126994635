import React, { useRef, Fragment, useState } from 'react';
import {
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Button,
  ClickAwayListener,
} from '@mui/material';
import { ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';

const ButtonExportExcel = ({
  handleCurrentDownload,
  handleAllDownload,
  disableAllExport = false,
  buttonSize = 'medium',
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        size={buttonSize}
      >
        <Button
          onClick={handleCurrentDownload}
          startIcon={<i className="far fa-file-excel"></i>}
        >
          Export
        </Button>
        {!disableAllExport && (
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ChevronDown />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key="1"
                    onClick={(event) => handleCurrentDownload(event)}
                  >
                    Export รายการในตาราง
                  </MenuItem>
                  {!disableAllExport && (
                    <MenuItem
                      key="1"
                      onClick={(event) => handleAllDownload(event)}
                    >
                      Export รายการทั้งหมด
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

ButtonExportExcel.propTypes = {
  handleCurrentDownload: PropTypes.func,
  handleAllDownload: PropTypes.func,
  disableAllExport: PropTypes.bool,
  buttonSize: PropTypes.string,
};

export default ButtonExportExcel;
