/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  User as UserIcon,
  FileText as FileTextIcon,
  Sliders as SlidersIcon,
  Archive as ArchiveIcon,
  UserPlus as UserPlusIcon,
  Layout as LayoutIcon,
  FileMinus as FileMinusIcon,
  Percent as PercentIcon,
} from 'react-feather';

import { io } from 'socket.io-client';
// import background from '../assets/images/BG-2.webp';

import { NavbarHome } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export default function Home() {
  const socket = useRef();

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const setting = useSelector((state) => state.setting);

  const [onlineUsers, setOnlineUsers] = useState([]);

  console.log('onlineUsers', onlineUsers);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    const fetchMe = async () => {
      try {
        dispatch(actions.meGet());
        dispatch(actions.settingAll());
      } catch (error) {
        console.error('Fetch Me Error', error);
      }
    };

    const fetchAvailableModule = async () => {
      try {
        dispatch(actions.availableModuleAll());
      } catch (error) {
        console.error('Fetch Available Module Error', error);
      }
    };

    fetchMe();
    fetchAvailableModule();

    return () => {};
  }, []);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    console.log('add user to socket', me?.userData?.id);
    if (me?.userData?.id) {
      socket.current.emit('new-user-add', me?.userData?.id);
    }
  }, [me]);

  useEffect(() => {
    socket.current.on('get-users', (users) => {
      console.log('users online', users);
      setOnlineUsers(users);
    });
  }, [me]);

  if (!me?.userData) {
    return <Loading />;
  }

  const menuList = [
    {
      moduleName: 'PROFILE',
      link: '/profile',
      icon: <UserIcon className="text-white" size={48} />,
      title: 'โปรไฟล์',
      extendedStyle: 'bg-gray-400',
    },
    {
      moduleName: 'DASHBOARD',
      link: '/dashboard',
      icon: <BarChartIcon className="text-white" size={48} />,
      title: 'แดชบอร์ด',
      extendedStyle: 'bg-gray-600 ',
    },
    {
      moduleName: 'SPM',
      link: '/spm/online',
      icon: <FileTextIcon className="text-white" size={48} />,
      title: 'การซื้อขาย',
      extendedStyle: 'bg-indigo-600 ',
    },
    {
      moduleName: 'WMS',
      link: '/wms/products',
      icon: <ArchiveIcon className="text-white" size={48} />,
      title: 'คลังสินค้า',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'HISTORY',
      link: '/history/online',
      icon: <FileMinusIcon className="text-white" size={48} />,
      title: 'จัดการประวัติ',
      extendedStyle: 'bg-yellow-800',
    },
    {
      moduleName: 'CRM',
      link: '/crm/customers',
      icon: <UsersIcon className="text-white" size={48} />,
      title: 'ลูกค้าสัมพันธ์',
      extendedStyle: 'bg-green-600 ',
    },
    {
      moduleName: 'HRMS',
      link: '/hrms/employee',
      icon: <UserPlusIcon className="text-white" size={48} />,
      title: 'ทรัพยากรบุคคล',
      extendedStyle: 'bg-yellow-500 ',
    },
    {
      moduleName: 'CMS',
      link: '/cms/main-site',
      icon: <LayoutIcon className="text-white" size={48} />,
      title: 'จัดการเว็บไซต์',
      extendedStyle: 'bg-red-500',
    },
    {
      moduleName: 'DISCOUNT',
      link: '/discount/promotion',
      icon: <PercentIcon className="text-white" size={48} />,
      title: 'จัดการส่วนลด และคูปอง',
      extendedStyle: 'bg-red-700',
    },
    {
      moduleName: 'SETTING',
      link: '/setting',
      icon: <SlidersIcon className="text-white" size={48} />,
      title: 'การตั้งค่า',
      extendedStyle: 'bg-black',
    },
  ];

  if (!accessRight(me)) {
    return (
      <div>
        <div className="min-h-screen bg-white">
          <Helmet>
            <meta charSet="utf-8" />
            <title>หน้าหลัก - {setting?.name || 'ระบบ E-OnlineShop'}</title>
          </Helmet>
          <NavbarHome />
          <div className="min-h-screen ">
            <div className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"></div>
            <div className="container pt-40 mr-auto ml-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
                {_.map(menuList, (_menu, index) => {
                  if (accessRight(me, _menu.moduleName, 0, availableModule)) {
                    return (
                      <div className="my-4  px-4" key={index}>
                        <Link to={_menu.link}>
                          <div
                            className={`  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 ${_menu.extendedStyle}`}
                          >
                            <div className="flex justify-center">
                              {_menu.icon}
                            </div>
                            <p className=" text-white mt-4 ">{_menu.title}</p>
                          </div>
                        </Link>
                      </div>
                    );
                    // eslint-disable-next-line no-else-return
                  }
                })}
              </div>
            </div>
          </div>
          {/* user online
          {_.map(onlineUsers, (user) => (
            <div>{user?.userId}</div>
          ))}
          {process.env.REACT_APP_SOCKET_URL} */}
          <button onClick={() => socket.current.emit('new-order', 'new-order')}>
            test socket
          </button>
          <div className="w-full">
            <MainFooter />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <NavbarHome />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}
