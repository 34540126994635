import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import { Trash2 as Delete } from 'react-feather';

export function PromotionProduct({
  handleDeletePromotionProductType,
  promotionProductType,
  handleChangeProductDiscountFollow,
  selectProductDiscountFollow,
  handleChangeProductDiscount,
  selectProductDiscount,
  selectProductType,
  onSubmitDiscountProductType,
  newArrayProductType,
  setSelectProductType,
  Controller,
  control,
  checkPromotionProduct,
  handleChangesetCheckPromotionProduct,
  handleOpenModalAddCustomerByProductType,
  checkAddCustomerByProductTypeArray,
}) {
  const renderTableDiscountProduct = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ประเภทสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ลดเพิ่ม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ลดตาม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(promotionProductType.rows) ? (
                _.map(promotionProductType.rows, (eachProductType, index) => (
                  <TableRow
                    key={eachProductType.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-4">
                        {eachProductType?.product_type?.name}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-4">{eachProductType?.percent} %</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-4">
                        {eachProductType?.follow_percent} %
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="gap-1">
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeletePromotionProductType(eachProductType);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ยังไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div className="m-4">
      <div className="mb-4 flex justify-between">
        <FormControlLabel
          control={
            <Switch
              checked={checkPromotionProduct}
              onChange={handleChangesetCheckPromotionProduct}
            />
          }
          label="ปิด-เปิด"
        />
        {selectProductType && (
          <Button
            variant="contained"
            onClick={() => {
              handleOpenModalAddCustomerByProductType();
            }}
          >
            เลือกกลุ่มลูกค้า ( {checkAddCustomerByProductTypeArray?.length || 0}{' '}
            )
          </Button>
        )}
      </div>

      <div className="mt-6 text-lg pb-2">เพิ่มส่วนลด</div>

      <div className="w-full flex rounded-lg border-2 p-2">
        <div className="w-1/2 px-1 py-2">
          <Controller
            name={'productType'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setSelectProductType(newValue);
                }}
                options={newArrayProductType}
                getOptionLabel={(type) => `${type?.name}`}
                renderInput={(params) => (
                  <TextField {...params} label="เลือกประเภทสินค้า" required />
                )}
              />
            )}
          />
        </div>

        <div className="w-1/2 py-2">
          {selectProductType && (
            <div className="flex gap-1 ">
              <div className="w-2/5">
                <TextField
                  size="small"
                  fullWidth
                  value={selectProductDiscount}
                  onChange={handleChangeProductDiscount}
                  type="number"
                  label="ลดเพิ่ม"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-2/5">
                <TextField
                  size="small"
                  fullWidth
                  value={selectProductDiscountFollow}
                  onChange={handleChangeProductDiscountFollow}
                  type="number"
                  label="ลดตาม"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="my-auto w-1/5">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => onSubmitDiscountProductType()}
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 text-lg pb-2">รายการส่วนลด</div>
      <div>{renderTableDiscountProduct()}</div>
    </div>
  );
}

PromotionProduct.propTypes = {
  customer: PropTypes.object,
  handleDeletePromotionProductType: PropTypes.func,
  promotionProductType: PropTypes.object,
  handleChangeProductDiscountFollow: PropTypes.func,
  selectProductDiscountFollow: PropTypes.object,
  handleChangeProductDiscount: PropTypes.func,
  selectProductDiscount: PropTypes.object,
  selectProductType: PropTypes.object,
  onSubmitDiscountProductType: PropTypes.func,
  newArrayProductType: PropTypes.array,
  setSelectProductType: PropTypes.func,
  Controller: PropTypes.func,
  setting: PropTypes.object,
  control: PropTypes.func,
  checkPromotionProduct: PropTypes.bool,
  handleChangesetCheckPromotionProduct: PropTypes.func,
  handleOpenModalAddCustomerByProductType: PropTypes.func,
  checkAddCustomerByProductTypeArray: PropTypes.array,
};

PromotionProduct.defaultProps = {
  customer: null,
};

export default PromotionProduct;
