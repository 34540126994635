import {
  TextField,
  Card,
  FormControlLabel,
  InputAdornment,
  Switch,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import ImageUpload from '../../ImageUpload/ImageUpload';
import PlaceholderLogo from '../../../assets/images/diprom.png';

export function InformationForm({
  errors,
  information,
  Controller,
  control,
  selectedImage,
  setSelectedImage,
  handleChangeCheckDeposit,
  handleChangeCheckBanking,
  handleChangeCheckBanking2,
  // handleChangeCheckBanking3,
  handleChangeCheckPromptPay,
  // handleChangeCheckPromptPay2,
  checkDeposit,
  checkBanking,
  checkBanking2,
  // checkBanking3,
  checkPromptPay,
  // checkPromptPay2,
  QRPromptpay1,
  // QRPromptpay2,
  setQRPromptpay1,
  // setQRPromptpay2,
}) {
  console.log('information in from', information);
  const renderSystemInfo = () => (
    <div className="flex flex-wrap">
      <div className="w-full text-center">โลโก้ระบบ</div>
      <div className="w-full px-1 py-2 flex justify-center my-2 border-2">
        <img
          src={information?.logo?.url || PlaceholderLogo}
          className="h-48 rounded-md"
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={information ? information.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={information ? information.description : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="คำอธิบาย"
              fullWidth
              size={'small'}
              required
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'url'}
          control={control}
          defaultValue={information ? information.url : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              type="url"
              label="URL ของระบบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.url && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'website_url'}
          control={control}
          defaultValue={information ? information.website_url : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              type="url"
              label="URL ของเว็ปไซต์"
              fullWidth
              size={'small'}
              helperText={errors.url && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'sponsor.name'}
          control={control}
          defaultValue={information ? information?.sponsor?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อผู้สนับสนุนระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.sponsor?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'sponsor.website'}
          control={control}
          defaultValue={information ? information?.sponsor?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์ผู้สนับสนุนระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.sponsor?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 justify-center">
        <ImageUpload
          title="อัพโหลดโลโก้"
          images={selectedImage}
          maxNumber={1}
          previewSize={400}
          setImages={setSelectedImage}
        />
      </div>
    </div>
  );
  const renderCompanyInfo = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.name'}
          control={control}
          defaultValue={information ? information.owner?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อบริษัท / หน่วยงาน เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.website'}
          control={control}
          defaultValue={information ? information.owner?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.telephone'}
          control={control}
          defaultValue={information ? information.owner?.telephone : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.telephone && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.email'}
          control={control}
          defaultValue={information ? information.owner?.email : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'owner.tax'}
          control={control}
          defaultValue={information ? information.owner?.tax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.tax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 lg:flex">
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'owner.address.house_number'}
            control={control}
            defaultValue={
              information ? information.owner?.address?.house_number : ''
            }
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขที่"
                fullWidth
                size={'small'}
                multiline={true}
                helperText={
                  errors?.owner?.address?.house_number && 'กรุณาใส่ข้อมูล'
                }
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'owner.address.soi'}
            control={control}
            defaultValue={information ? information.owner?.address?.soi : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ซอย"
                fullWidth
                size={'small'}
                multiline={true}
                helperText={errors?.owner?.address?.soi && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.village_number'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.village_number : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมู่ที่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.village_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.road'}
          control={control}
          defaultValue={information ? information.owner?.address?.road : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.road && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.subdistrict'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.subdistrict : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำบล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.subdistrict && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.district'}
          control={control}
          defaultValue={information ? information.owner?.address?.district : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อำเภอ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.district && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.province'}
          control={control}
          defaultValue={information ? information.owner?.address?.province : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จังหวัด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.province && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.postcode'}
          control={control}
          defaultValue={information ? information.owner?.address?.postcode : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสไปรษณีย์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.postcode && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.country'}
          control={control}
          defaultValue={information ? information.owner?.address?.country : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ประเทศ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.country && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'available_time'}
          control={control}
          defaultValue={information ? information?.available_time : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เวลาทำการ"
              fullWidth
              size={'small'}
              multiline={true}
            />
          )}
        />
      </div>
    </div>
  );

  const renderBankingForm = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkBanking}
              onChange={handleChangeCheckBanking}
            />
          }
          label="บัญชีธนาคาร1"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNameBank'}
            control={control}
            defaultValue={information ? information.banking?.accountName : ''}
            rules={{ required: checkBanking }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking}
                disabled={!checkBanking}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'bankName'}
            control={control}
            defaultValue={information ? information.banking?.bankName : ''}
            rules={{ required: checkBanking }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อธนาคาร"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking}
                disabled={!checkBanking}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'accountNumberBank'}
            control={control}
            defaultValue={information ? information.banking?.accountNumber : ''}
            rules={{ required: checkBanking }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking}
                disabled={!checkBanking}
              />
            )}
          />
        </div>
        <div className="w-full">
          <ImageUpload
            profile={1}
            preview_size="250"
            maxNumber={1}
            images={QRPromptpay1}
            setImages={setQRPromptpay1}
            title={QRPromptpay1[0] ? 'แก้ไขรูปภาพ' : 'อัพโหลดรูปภาพ'}
          />
        </div>
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkBanking2}
              onChange={handleChangeCheckBanking2}
            />
          }
          label="บัญชีธนาคาร2"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNameBank2'}
            control={control}
            defaultValue={information ? information.banking2?.accountName : ''}
            rules={{ required: checkBanking2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking2}
                disabled={!checkBanking2}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'bankName2'}
            control={control}
            defaultValue={information ? information.banking2?.bankName : ''}
            rules={{ required: checkBanking2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อธนาคาร"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking2}
                disabled={!checkBanking2}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'accountNumberBank2'}
            control={control}
            defaultValue={
              information ? information.banking2?.accountNumber : ''
            }
            rules={{ required: checkBanking2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking2}
                disabled={!checkBanking2}
              />
            )}
          />
        </div>
      </div>
      {/* <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkBanking3}
              onChange={handleChangeCheckBanking3}
            />
          }
          label="บัญชีธนาคาร3"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNameBank3'}
            control={control}
            defaultValue={information ? information.banking3?.accountName : ''}
            rules={{ required: checkBanking3 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking3}
                disabled={!checkBanking3}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'bankName3'}
            control={control}
            defaultValue={information ? information.banking3?.bankName : ''}
            rules={{ required: checkBanking3 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อธนาคาร"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking3}
                disabled={!checkBanking3}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'accountNumberBank3'}
            control={control}
            defaultValue={
              information ? information.banking3?.accountNumber : ''
            }
            rules={{ required: checkBanking3 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking3}
                disabled={!checkBanking3}
              />
            )}
          />
        </div>
      </div> */}
    </>
  );

  const renderPromptpayForm = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkPromptPay}
              onChange={handleChangeCheckPromptPay}
            />
          }
          label="พร้อมเพย์"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountName'}
            control={control}
            defaultValue={information ? information.promptpay?.accountName : ''}
            rules={{ required: checkPromptPay }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay}
                disabled={!checkPromptPay}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNumber'}
            control={control}
            defaultValue={
              information ? information.promptpay?.accountNumber : ''
            }
            rules={{ required: checkPromptPay }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเลขพร้อมเพย์"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay}
                disabled={!checkPromptPay}
              />
            )}
          />
        </div>
        <div className="text-red-500">
          * ใช้สำหรับสร้างคิวอาร์โค๊ดการชำระเงิน
        </div>
      </div>
      {/* <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkPromptPay2}
              onChange={handleChangeCheckPromptPay2}
            />
          }
          label="พร้อมเพย์2"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountName2'}
            control={control}
            defaultValue={
              information ? information.promptpay2?.accountName : ''
            }
            rules={{ required: checkPromptPay }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay2}
                disabled={!checkPromptPay2}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNumber2'}
            control={control}
            defaultValue={
              information ? information.promptpay2?.accountNumber : ''
            }
            rules={{ required: checkPromptPay2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเลขพร้อมเพย์"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay2}
                disabled={!checkPromptPay2}
              />
            )}
          />
        </div>
        <div className="text-red-500">
          * ใช้สำหรับสร้างคิวอาร์โค๊ดการชำระเงิน
        </div>
      </div> */}
    </>
  );

  const renderDepositForm = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkDeposit}
              onChange={handleChangeCheckDeposit}
            />
          }
          label="การมัดจำ"
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'percent'}
          control={control}
          defaultValue={information ? information?.deposit?.percent : ''}
          rules={{ required: checkDeposit }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เปอร์เซ็นต์การมัดจำ"
              fullWidth
              size={'small'}
              multiline={true}
              disabled={!checkDeposit}
              required={checkDeposit}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          )}
        />
      </div>
    </>
  );

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/12 px-2 mb-2">
          <Card className="p-4">
            <div className="font-semibold ">ข้อมูลระบบ</div>
            {renderSystemInfo()}
          </Card>
        </div>

        <div className="w-full lg:w-8/12 px-2 ">
          <div>
            <Card className="p-4">
              <div className="font-semibold mb-2">ข้อมูลบริษัท</div>
              {renderCompanyInfo()}
            </Card>
          </div>
          <div className="pt-4">
            <Card className="p-4">
              <div className="font-semibold mb-2">ข้อมูลการชำระเงิน</div>
              {renderBankingForm()}
              {renderPromptpayForm()}
              {renderDepositForm()}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

InformationForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    owner: PropTypes.object,
    sponsor: PropTypes.object,
    url: PropTypes.object,
    payment: PropTypes.object,
    banking: PropTypes.object,
    promptpay: PropTypes.object,
  }),
  information: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  selectedImage: PropTypes.arrayOf(PropTypes.object),
  setSelectedImage: PropTypes.node.isRequired,
  handleChangeCheckDeposit: PropTypes.func,
  handleChangeCheckBanking: PropTypes.func,
  handleChangeCheckBanking2: PropTypes.func,
  handleChangeCheckBanking3: PropTypes.func,
  handleChangeCheckPromptPay: PropTypes.func,
  handleChangeCheckPromptPay2: PropTypes.func,
  checkDeposit: PropTypes.bool,
  checkBanking: PropTypes.bool,
  checkBanking2: PropTypes.bool,
  checkBanking3: PropTypes.bool,
  checkPromptPay: PropTypes.bool,
  checkPromptPay2: PropTypes.bool,
  setQRPromptpay1: PropTypes.func,
  setQRPromptpay2: PropTypes.func,
  QRPromptpay1: PropTypes.array,
  QRPromptpay2: PropTypes.array,
};

InformationForm.defaultProps = {
  information: null,
};

export default InformationForm;
