import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
} from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';

import { Trash2 as Delete } from 'react-feather';
import NestedDiscount from './NestedDiscount';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function PromotionWholesale({
  checkAddCustomerByWholesaleArray,
  handleOpenModalAddCustomerByWholesale,
  handleChangesetCheckWholesaleDiscount,
  checkWholesaleDiscount,
  handleDeletePromotionWholesale,
  setSelectDetailPromotionWholesale,
  handleOpenModalPromotionWholesale,
  promotionWholesale,
  handleAddItemWholesaleList,
  handleDeleteItemWholesaleList,
  Controller,
  wholesaleControl,
  wholesaleFields,
  wholesaleHandleSubmit,
  onSubmitPromotionWholesaleList,
  selectProductTypeWholesale,
  NewProductTypeWholesale,
  setSelectProductTypeWholesale,
  setSelectProductTypeWholesaleArray,
  selectProductTypeWholesaleArray,
  setSelectEditPromotionWholesale,
  selectEditPromotionWholesale,
  loading,
  setLoading,
  wholesaleReset,
  wholesaleSetValue,
  wholesaleRemove,
}) {
  // console.log('promotionWholesale', promotionWholesale);
  // console.log('NewProductTypeWholesale', NewProductTypeWholesale);
  console.log('selectEditPromotionWholesale', selectEditPromotionWholesale);

  const renderTablePromotionWithWholesale = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ประเภทสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(promotionWholesale.rows) ? (
                _.map(promotionWholesale.rows, (eachPromotion, index) => (
                  <TableRow
                    key={eachPromotion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {_.map(
                        eachPromotion?.product_type_array,
                        (eachProductType, index1) => (
                          <div className="py-1" key={index1}>
                            - {eachProductType?.product_type?.name}
                          </div>
                        ),
                      )}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          startIcon={<DescriptionIcon />}
                          size={'small'}
                          onClick={() => {
                            handleOpenModalPromotionWholesale();
                            setSelectDetailPromotionWholesale(eachPromotion);
                          }}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => {
                            setLoading(false);
                            setSelectEditPromotionWholesale({
                              ...eachPromotion,
                              index: index + 1,
                            });
                          }}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeletePromotionWholesale(eachPromotion);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ยังไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div className="m-4">
      <div className="mb-4 flex justify-between">
        <FormControlLabel
          control={
            <Switch
              checked={checkWholesaleDiscount}
              onChange={handleChangesetCheckWholesaleDiscount}
            />
          }
          label="ปิด-เปิด"
        />
        <div className="flex gap-1">
          <Button
            variant="contained"
            onClick={() => {
              wholesaleSetValue('wholesaleList', [
                {
                  discount_array: [
                    {
                      quantity: 0,
                      percent: 0,
                      follow_percent1: 0,
                      follow_percent2: 0,
                      whole_sale: false,
                    },
                  ],
                },
              ]);
              setSelectEditPromotionWholesale();
            }}
          >
            รีเซ็ต
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleOpenModalAddCustomerByWholesale();
            }}
          >
            เลือกกลุ่มลูกค้า ( {checkAddCustomerByWholesaleArray?.length || 0} )
          </Button>
        </div>
      </div>
      <div className="mt-6 text-lg pb-2">เพิ่มส่วนลด</div>
      <form onSubmit={wholesaleHandleSubmit(onSubmitPromotionWholesaleList)}>
        <div className="w-full rounded-lg border-2 ">
          {!_.isEmpty(wholesaleFields) &&
            _.map(wholesaleFields, (item, index) => (
              <div className="w-full p-2 flex" key={item.id}>
                <div>
                  <div className="w-full px-1 py-2">
                    {!promotionWholesale?.isLoading && (
                      <Controller
                        name={`wholesaleList[${index}].productType`}
                        control={wholesaleControl}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={NewProductTypeWholesale}
                            getOptionLabel={(type) => `${type?.name}`}
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                            }}
                            defaultValue={item?.product_type}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="เลือกประเภทสินค้า"
                              />
                            )}
                          />
                        )}
                      />
                    )}
                    <NestedDiscount
                      nestIndex={index}
                      control={wholesaleControl}
                      Controller={Controller}
                    />
                  </div>
                </div>
                <div className="my-2 h-full">
                  <Button
                    fullWidth
                    fullHeight
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      wholesaleRemove(index);
                    }}
                  >
                    <Delete size={16} color="red" />
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-between gap-1 py-4">
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={() => {
              handleAddItemWholesaleList();
            }}
          >
            เพิ่มประเภทสินค้า
          </Button>
          <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
            บันทึก
          </Button>
        </div>
      </form>

      <div className="mt-6 text-lg pb-2">
        รายการประเภทสินค้าที่มีส่วนลดขายส่งเเล้ว
      </div>
      <div>{renderTablePromotionWithWholesale()}</div>
    </div>
  );
}

PromotionWholesale.propTypes = {
  setting: PropTypes.object,
  handleOpenModalAddCustomerByWholesale: PropTypes.func,
  handleChangesetCheckWholesaleDiscount: PropTypes.func,
  checkWholesaleDiscount: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  handleDeletePromotionWholesale: PropTypes.func,
  setSelectDetailPromotionWholesale: PropTypes.func,
  handleOpenModalPromotionWholesale: PropTypes.func,
  promotionWholesale: PropTypes.object,
  handleAddItemWholesaleList: PropTypes.func,
  handleDeleteItemWholesaleList: PropTypes.func,
  Controller: PropTypes.func,
  wholesaleControl: PropTypes.func,
  wholesaleFields: PropTypes.func,
  wholesaleHandleSubmit: PropTypes.func,
  onSubmitPromotionWholesaleList: PropTypes.func,
  selectProductTypeWholesale: PropTypes.object,
  NewProductTypeWholesale: PropTypes.array,
  checkAddCustomerByWholesaleArray: PropTypes.array,
  selectProductTypeWholesaleArray: PropTypes.array,
  selectEditPromotionWholesaleArray: PropTypes.array,
  selectEditPromotionWholesale: PropTypes.object,
  setSelectProductTypeWholesale: PropTypes.func,
  setSelectProductTypeWholesaleArray: PropTypes.func,
  setSelectEditPromotionWholesale: PropTypes.func,
  wholesaleReset: PropTypes.func,
  wholesaleSetValue: PropTypes.func,
  wholesaleRemove: PropTypes.func,
};

PromotionWholesale.defaultProps = {
  customer: null,
};

export default PromotionWholesale;
