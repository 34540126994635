import CreateOrder from './CreateOrder';
import Orders from './Orders';
import DetailOrder from './DetailOrder';
import CreateExport from './CreateExport';
import Exports from './Exports';
import DetailExport from './DetailExport';
import Onlines from './Onlines';
import DetailOnline from './DetailOnline';

export {
  CreateOrder,
  Orders,
  DetailOrder,
  CreateExport,
  Exports,
  DetailExport,
  Onlines,
  DetailOnline,
};
export default {
  CreateOrder,
  Orders,
  DetailOrder,
  CreateExport,
  Exports,
  DetailExport,
  Onlines,
  DetailOnline,
};
