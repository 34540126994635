import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  MainSite,
  ProductSite,
  ServiceSite,
  ContactSite,
  PortfolioSite,
  CreatePortfolio,
  PrivacyPolicy,
  BusinessPolicy,
  CookiesPolicy,
  Sponsor,
  EditSponsor,
  SettingLayout,
} from '../views/CMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function CMS() {
  const module = 'CMS';
  const prefix = '/cms';
  const name = 'จัดการเว็บไซต์';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: SiteInfo');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="min-h-screen  pt-16 px-8 flex w-full">
          <MainSidebar
            onMobileClose={handleOnMobileNavClose}
            openMobile={isMobileNavOpen}
            me={me}
          />
          <div className="py-4 w-full">
            <Switch>
              {accessRight(me, module, 1, availableModule) && (
                <Switch>
                  <Redirect
                    exact
                    from={`${prefix}`}
                    to={`${prefix}/main-site`}
                  />
                  <Route exact path={`${prefix}/main-site`}>
                    <MainSite title="จัดการหน้าหลักเว็บไซต์" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/product-site`}>
                    <ProductSite title="ข้อมูลหน้าแสดงสินค้า" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/service-site`}>
                    <ServiceSite title="ข้อมูลหน้าแสดงบริการ" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/setting-layout`}>
                    <SettingLayout title="จัดการเลเอาท์" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/contact-site`}>
                    <ContactSite title="ข้อมูลการติดต่อ" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/sponsor`}>
                    <Sponsor title="สปอนเซอร์/พาร์ทเนอร์" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/portfolio`}>
                    <PortfolioSite
                      title="ข้อมูลหน้าแสดงผลงาน"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/portfolio/create`}>
                    <CreatePortfolio title="เพิ่มผลงาน" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/portfolio/edit/:id`}>
                    <PortfolioSite title="แก้ไขผลงาน" subtitle={name} />
                  </Route>

                  <Route exact path={`${prefix}/sponsor/edit/:id`}>
                    <EditSponsor
                      title="แก้ไขสปอนเซอร์/พาร์ทเนอร์"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/portfolio/:id`}>
                    <PortfolioSite title="รายละเอียดผลงาน" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/privacy-policy`}>
                    <PrivacyPolicy
                      title="นโยบายความเป็นส่วนตัว"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/cookies-policy`}>
                    <CookiesPolicy
                      title="นโยบายการใช้งานคุกกี้"
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/business-policy`}>
                    <BusinessPolicy title="นโยบายทางธุรกิจ" subtitle={name} />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotAuthorized />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default CMS;
