import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { InformationForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function Information({ title, subtitle }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const [logoImage, setLogoImage] = useState([]);

  const [QRPromptpay1, setQRPromptpay1] = useState([]);
  const [QRPromptpay2, setQRPromptpay2] = useState([]);
  const [checkPromptPay, setCheckPromptPay] = useState(
    setting?.promptpay?.available,
  );
  const [checkPromptPay2, setCheckPromptPay2] = useState(
    setting?.promptpay2?.available,
  );
  const [checkBanking, setCheckBanking] = useState(setting?.banking?.available);
  const [checkBanking2, setCheckBanking2] = useState(
    setting?.banking2?.available,
  );
  const [checkBanking3, setCheckBanking3] = useState(
    setting?.banking3?.available,
  );

  console.log('QRPromptpay1', QRPromptpay1);

  const [checkDeposit, setCheckDeposit] = useState(setting?.deposit?.available);

  const handleChangeCheckPromptPay = (event) => {
    setCheckPromptPay(event.target.checked);
  };
  const handleChangeCheckPromptPay2 = (event) => {
    setCheckPromptPay2(event.target.checked);
  };

  const handleChangeCheckBanking = (event) => {
    setCheckBanking(event.target.checked);
  };
  const handleChangeCheckBanking2 = (event) => {
    setCheckBanking2(event.target.checked);
  };
  const handleChangeCheckBanking3 = (event) => {
    setCheckBanking3(event.target.checked);
  };

  const handleChangeCheckDeposit = (event) => {
    setCheckDeposit(event.target.checked);
  };

  useEffect(() => {
    dispatch(actions.settingAll());
    return () => {};
  }, []);

  useEffect(() => {
    setCheckPromptPay(setting?.promptpay?.available);
    setCheckPromptPay2(setting?.promptpay2?.available);
    setCheckBanking(setting?.banking?.available);
    setCheckBanking2(setting?.banking2?.available);
    setCheckBanking3(setting?.banking3?.available);
    setCheckDeposit(setting?.deposit?.available);
    setQRPromptpay1([setting?.promptpay?.QrCode]);
    setQRPromptpay2([setting?.promptpay2?.QrCode]);
    return () => {};
  }, [setting]);

  const onSubmit = async (data, event) => {
    try {
      console.log('data', data);
      const payload = {
        ...data,
        banking: {
          accountName: data.accountName,
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          available: checkBanking,
        },
        banking2: {
          accountName: data.accountNameBank2,
          bankName: data.bankName2,
          accountNumber: data.accountNumberBank2,
          available: checkBanking2,
        },
        // banking3: {
        //   accountName: data.accountName3,
        //   bankName: data.bankName3,
        //   accountNumber: data.accountNumber3,
        //   available: checkBanking3,
        // },
        promptpay: {
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          available: checkPromptPay,
          QrCode: QRPromptpay1[0]?.url
            ? QRPromptpay1[0]?.id
            : {
                image: QRPromptpay1[0]?.data_url,
                imageType: 'QRPromptpay1',
                alt: '',
              },
        },

        // promptpay2: {
        //   accountName: data.accountName2,
        //   accountNumber: data.accountNumber2,
        //   available: checkPromptPay2,
        // },
        deposit: {
          percent: data.percent,
          available: checkDeposit,
        },
        logo: {
          image: logoImage[0]?.data_url,
          imageType: 'logo',
          alt: '',
        },
        images: _.map(setting?.banner, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image.image_id,
          image: image.data_url || image.image.url,
        })),
      };

      const payloadNoImage = {
        ...data,
        banking: {
          accountName: data.accountNameBank,
          bankName: data.bankName,
          accountNumber: data.accountNumberBank,
          available: checkBanking,
        },
        banking2: {
          accountName: data.accountNameBank2,
          bankName: data.bankName2,
          accountNumber: data.accountNumberBank2,
          available: checkBanking2,
        },
        // banking3: {
        //   accountName: data.accountName3,
        //   bankName: data.bankName3,
        //   accountNumber: data.accountNumber3,
        //   available: checkBanking3,
        // },
        promptpay: {
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          available: checkPromptPay,
          QrCode: QRPromptpay1[0]?.url
            ? QRPromptpay1[0]?.id
            : {
                image: QRPromptpay1[0]?.data_url,
                imageType: 'QRPromptpay1',
                alt: '',
              },
        },

        // promptpay2: {
        //   accountName: data.accountName2,
        //   accountNumber: data.accountNumber2,
        //   available: checkPromptPay2,
        // },
        deposit: {
          percent: data.percent,
          available: checkDeposit,
        },
        images: _.map(setting?.banner, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image.image_id,
          image: image.data_url || image.image.url,
        })),
      };

      console.log('payload on setting', payload);
      console.log('payloadNoImage on setting', payloadNoImage);

      console.log('!_.isEmpty(logoImage', !_.isEmpty(logoImage));

      event.preventDefault();
      if (!_.isEmpty(logoImage)) {
        await dispatch(actions.settingPut(setting.id, payload));
        setLogoImage([]);
      } else {
        await dispatch(actions.settingPut(setting.id, payloadNoImage));
      }
      alert('สำเร็จ');
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InformationForm
        Controller={Controller}
        control={control}
        errors={errors}
        information={setting}
        selectedImage={logoImage}
        setSelectedImage={setLogoImage}
        handleChangeCheckDeposit={handleChangeCheckDeposit}
        handleChangeCheckBanking={handleChangeCheckBanking}
        handleChangeCheckBanking2={handleChangeCheckBanking2}
        handleChangeCheckBanking3={handleChangeCheckBanking3}
        handleChangeCheckPromptPay={handleChangeCheckPromptPay}
        handleChangeCheckPromptPay2={handleChangeCheckPromptPay2}
        checkDeposit={checkDeposit}
        checkBanking={checkBanking}
        checkBanking2={checkBanking2}
        checkBanking3={checkBanking3}
        checkPromptPay={checkPromptPay}
        checkPromptPay2={checkPromptPay2}
        QRPromptpay1={QRPromptpay1}
        QRPromptpay2={QRPromptpay2}
        setQRPromptpay1={setQRPromptpay1}
        setQRPromptpay2={setQRPromptpay2}
      />
      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (setting.isLoading) {
    return <Loading />;
  }
  if (!setting.isLoading && setting.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Information.defaultProps = {
  title: '',
  subtitle: '',
};

export default Information;
