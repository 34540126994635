import {
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import dayjs from 'dayjs';

import thLocale from 'date-fns/locale/th';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

export function CouponForm({
  errors,
  coupon,
  Controller,
  control,
  handleChangeType,
  selectType,
  handleChangeEndDate,
  handleChangeStartDate,
  startDate,
  endDate,
}) {
  const [locale] = useState('th');

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={coupon ? coupon.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อคูปอง"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'code'}
          control={control}
          defaultValue={coupon ? coupon.code : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="โค๊ด"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={coupon ? coupon.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียดคูปอง"
              multiline
              rows={2}
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          defaultValue={coupon ? coupon.type : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทส่วนลด
              </InputLabel>
              <Select
                {...field}
                label="ประเภทส่วนลด"
                size={'small'}
                fullWidth
                required
              >
                <MenuItem value="delivery">ส่วนลดการจัดส่ง</MenuItem>
                <MenuItem value="bill">ส่วนลดท้ายบิล</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
          <InputLabel id="type" size={'small'}>
            เลือกวิธีการคิดค่าจัดส่ง
          </InputLabel>
          <Select
            label="เลือกวิธีการคิดค่าจัดส่ง"
            onChange={handleChangeType}
            defaultValue={coupon ? coupon?.type_value : ''}
            size={'small'}
            fullWidth
          >
            <MenuItem value="money">จำนวนเงิน</MenuItem>
            <MenuItem value="percent">เปอร์เซ็นต์</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'value'}
          control={control}
          defaultValue={coupon ? coupon.value : 0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={selectType === 'money' ? 'จำนวนเงิน' : 'เปอร์เซ็นต์'}
              fullWidth
              type="number"
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {selectType === 'money' ? 'บาท' : '%'}
                  </InputAdornment>
                ),
              }}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'min'}
          control={control}
          defaultValue={coupon ? coupon.min : 0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนเงินขั้นต่ำการสั่งซื้อ"
              fullWidth
              type="number"
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">บาท</InputAdornment>
                ),
              }}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'max_value'}
          control={control}
          defaultValue={coupon ? coupon.max_value : 0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนเงินสูงสุดในการลด"
              fullWidth
              type="number"
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">บาท</InputAdornment>
                ),
              }}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <DateTimePicker
            sx={{
              width: '100%',
            }}
            ampm={false}
            fullWidth
            mask={maskMap[locale]}
            label="เลือกวันและเวลาเริ่มต้นคูปอง"
            value={startDate}
            // defaultValue={coupon ? coupon.start_date : ''}
            onChange={handleChangeStartDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </div>
      <div className="w-1/2 px-1 py-2">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <DateTimePicker
            sx={{
              width: '100%',
            }}
            ampm={false}
            fullWidth
            mask={maskMap[locale]}
            label="เลือกวันและเวลาสิ้นสุดคูปอง"
            value={endDate}
            // defaultValue={coupon ? coupon.end_date : ''}
            onChange={handleChangeEndDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'inventory'}
          control={control}
          defaultValue={coupon ? coupon.inventory : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนโค๊ด"
              fullWidth
              size={'small'}
              type="number"
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
    </div>
  );
}

CouponForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  coupon: PropTypes.object,
  Controller: PropTypes.func,
  handleChangeType: PropTypes.func,
  handleChangeEndDate: PropTypes.func,
  handleChangeStartDate: PropTypes.func,
  control: PropTypes.object,
  selectType: PropTypes.string,
  endDate: PropTypes.datetime,
  startDate: PropTypes.datetime,
};

CouponForm.defaultProps = {
  brand: null,
};

export default CouponForm;
