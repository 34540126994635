/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import InfoIcon from '@mui/icons-material/Info';

import { useForm } from 'react-hook-form';
import { IncreaseSerialNumberDialog } from '../../Dialogs';
import { SPM } from '../../../utils/Proptypes';

const resetValues = {
  product: '',
  price: '',
  inventory: '',
  selectSerial: [],
  price1: '',
};

export const IncreaseProductForm = ({
  errors,
  // order,
  selectProduct,
  Controller,
  product,
  setSelectProduct,
  filterProduct,
  setFilterProduct,
  productList,
  setProductList,
  setReload,
  reload,
  selectSerial,
  setSelectSerial,
}) => {
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [defaultPrice, setDefaultPrice] = useState(' -');
  const { getValues, control, reset } = useForm();
  const [serials, setSerials] = useState([]);
  // console.log('productList in add product form', productList);
  console.log('selectProduct', selectProduct);

  const checkProduct = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    const findproduct = _.find(product, { id: data._id });
    setFilterProduct(findproduct);
    setDefaultPrice(parseInt(findproduct?.price, 10));
  };
  useEffect(() => () => {}, [reload]);

  // useEffect(() => {
  //   setSelectedProducts(order?.product_of_order);
  //   return () => {};
  // }, [order]);

  const handleCloseDialog = () => {
    try {
      setIsProductDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log('selectSerial', selectSerial);
  // console.log('serials', serials);

  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));

  const handleAddProduct = () => {
    console.log('get price ', getValues('price'));
    if (!filterProduct?.serial_type) {
      if (getValues('inventory') === '') {
        alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (getValues('inventory') > filterProduct?.inventory) {
          alert('สินค้ามีจำนวนคงเหลือไม่พอ');
        } else {
          // eslint-disable-next-line no-lonely-if
          if (getValues('price') === '') {
            const data = {
              product: filterProduct,
              inventory: getValues('inventory'),
              price: defaultPrice,
            };

            productList.push(data);
            setProductList(productList);
            setDefaultPrice('');
            reset(resetValues);
            setReload(!reload);
          } else {
            const data = {
              product: filterProduct,
              inventory: getValues('inventory'),
              price: getValues('price'),
            };

            productList.push(data);
            setProductList(productList);
            setDefaultPrice('');
            reset(resetValues);
            setReload(!reload);
          }
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (selectSerial.length === 0) {
        alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (getValues('price1') === '') {
          const data = {
            product: filterProduct,
            inventory: selectSerial.length,
            serials: selectSerial,
            price: defaultPrice,
          };
          console.log(data);
          productList.push(data);
          setProductList(productList);
          setSelectSerial([]);
          setDefaultPrice('');
          reset(resetValues);
          setReload(!reload);
        } else {
          const data = {
            product: filterProduct,
            inventory: selectSerial.length,
            serials: selectSerial,
            price: getValues('price1'),
          };
          console.log(data);
          productList.push(data);
          setProductList(productList);
          setSelectSerial([]);
          setDefaultPrice('');
          reset(resetValues);
          setReload(!reload);
        }
      }
    }
  };

  // console.log('defaultPrice', defaultPrice);

  // console.log('filterProduct', filterProduct);

  return (
    <div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'product'}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  checkProduct(newValue);
                  setSelectProduct(newValue);
                  setReload(!reload);
                }}
                options={NewProduct}
                getOptionLabel={(option) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  `${option?.index} : ${option?.name}  ( เหลือสินค้า ${option?.inventory}  ${option?.unit} )`
                }
                required
                renderInput={(params) => (
                  <TextField {...params} label="สินค้า" required />
                )}
              />
            )}
          />
        </div>
        {filterProduct?.serial_type === false &&
        !filterProduct?.option_type === true ? (
          <>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'price'}
                control={control}
                rules={{ required: true }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`ราคาต่อหน่วย (${defaultPrice} บาท)`}
                    fullWidth
                    type="number"
                    size={'small'}
                    // value={defaultPrice}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'inventory'}
                control={control}
                rules={{ required: true }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="จำนวน"
                    fullWidth
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full px-1 py-2">
              <Button
                variant="outlined"
                className="w-full"
                disabled={filterProduct === null}
                onClick={() => handleAddProduct()}
              >
                เพิ่ม
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'price1'}
                control={control}
                rules={{ required: true }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`ราคาต่อหน่วย (${defaultPrice} บาท)`}
                    fullWidth
                    type="number"
                    size={'small'}
                    // value={defaultPrice}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 px-1 py-2">
              <div className="w-full h-full">
                <Button
                  variant="contained"
                  className="w-full h-full"
                  onClick={() => setIsProductDialogOpen(true)}
                >
                  <p className="bg-white p-0.5 px-2 font-bold rounded-full text-gray-800">
                    {selectSerial.length}
                  </p>
                  <p className="pl-2">เพิ่ม SN</p>
                </Button>
              </div>
            </div>
            <IncreaseSerialNumberDialog
              open={isProductDialogOpen}
              handleClose={handleCloseDialog}
              selectSerial={selectSerial}
              setSelectSerial={setSelectSerial}
              productId={filterProduct?.id}
              serials={serials}
              setSerials={setSerials}
            />
            <div className="w-full h-full px-1 py-2">
              <Button
                variant="outlined"
                className="w-full h-full"
                disabled={filterProduct === null}
                onClick={() => handleAddProduct()}
              >
                เพิ่ม
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

IncreaseProductForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: PropTypes.object,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
};

IncreaseProductForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default IncreaseProductForm;
