import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  MaterialType,
  EditMaterialType,
  DetailMaterialType,
  MaterialTransactionType,
  EditMaterialTransactionType,
  Place,
  EditPlace,
  MaterialStock,
  MaterialHistory,
  Material,
  EditMaterial,
  DetailMaterial,
  DetailMaterialStock,
  AddMaterialStockLot,
} from '../views/IMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotAuthorized, NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

// TODO: เรายังไม่ได้ Level  Access Control นะ
export function IMS() {
  const module = 'IMS';
  const prefix = '/ims';
  const name = 'คลังวัตถุดิบ';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: IMS');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/material-stock`}
              />
              <Route exact path={`${prefix}/material-stock`}>
                <MaterialStock title="จัดการสต๊อกวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/place/:id`}>
                <DetailMaterialStock
                  title="จัดการสต๊อกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/create/:id`}>
                <AddMaterialStockLot
                  title="เพิ่ม ลดสต๊อกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-types`}>
                <MaterialType title="จัดการประเภทวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-types/edit/:id`}>
                <EditMaterialType title="แก้ไขประเภทวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-type/:id`}>
                <DetailMaterialType
                  title="รายละเอียดประเภทวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/materials`}>
                <Material title="จัดการข้อมูลวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material/edit/:id`}>
                <EditMaterial title="แก้ไขข้อมูลวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material/:id`}>
                <DetailMaterial
                  title="รายละเอียดข้อมูลวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/transaction-types`}>
                <MaterialTransactionType
                  title="จัดการประเภทการดำเนินการ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/transaction-types/edit/:id`}>
                <EditMaterialTransactionType
                  title="แก้ไขประเภทการดำเนินการ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/places`}>
                <Place title="จัดการคลังวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/place/edit/:id`}>
                <EditPlace title="แก้ไขคลังวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history`}>
                <MaterialHistory
                  title="ประวัติการจัดการวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default IMS;
