import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

const resetValues = {
  name: '',
  price: '',
  cost: '',
  weight: '',
  pack_size: '',
};

export function OptionForm({ selectOption, setSelectOption }) {
  const [reload, setReload] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm();
  useEffect(() => () => {}, [reload]);

  console.log('selectOption', selectOption);

  const handleAddOption = () => {
    if (
      getValues('name') === '' ||
      getValues('price') === '' ||
      getValues('cost') === ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        name: getValues('name'),
        price: getValues('price'),
        cost: getValues('cost'),
        weight: getValues('weight'),
        pack_size: getValues('pack_size'),
        inventory: 0,
      };
      // eslint-disable-next-line react/prop-types
      selectOption.push(data);
      setSelectOption(selectOption);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveOption = (index) => {
    // eslint-disable-next-line react/prop-types
    selectOption.splice(index, 1);
    setSelectOption(selectOption);
    setReload(!reload);
  };

  const displayOption = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100 ">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อออฟชั่น</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ต้นทุน(บาท)</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ราคาขาย(บาท)</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> น้ำหนัก(กรัม)</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน/ลัง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(selectOption) ? (
            selectOption.map((_option, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{_option?.name}</TableCell>
                <TableCell>{_option?.cost}</TableCell>
                <TableCell>{_option?.price}</TableCell>
                <TableCell>{_option?.weight}</TableCell>
                <TableCell>{_option?.pack_size}</TableCell>
                <TableCell>
                  <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => handleRemoveOption(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}></TableRow>
          )}
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Controller
                name={'name'}
                control={control}
                defaultValue={''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ชื่อออฟชั่น"
                    fullWidth
                    size={'small'}
                    helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name={'cost'}
                control={control}
                defaultValue={''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ต้นทุน(บาท)"
                    type="number"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name={'price'}
                control={control}
                defaultValue={''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ราคาขาย(บาท)"
                    type="number"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name={'weight'}
                control={control}
                defaultValue={''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="น้ำหนัก(กรัม)"
                    type="number"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <Controller
                name={'pack_size'}
                control={control}
                defaultValue={''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="จำนวน/ลัง"
                    type="number"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </TableCell>
            <TableCell>
              <Button variant="contained" onClick={() => handleAddOption()}>
                เพิ่ม
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  // eslint-disable-next-line no-confusing-arrow
  const showSelectOption = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    displayOption();

  return (
    <div className="w-full">
      {/* {checkDefaultMaterial(materialRequest)} */}

      <div className="w-full">
        {/* <div className="w-full">{addOptions()}</div> */}
        <div className="w-full">{showSelectOption()}</div>
      </div>
    </div>
  );
}

OptionForm.propTypes = {
  option: PropTypes.array,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
};

OptionForm.defaultProps = {
  option: null,
};

export default OptionForm;
