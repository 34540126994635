import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, CircularProgress, DialogContent } from '@mui/material';

import ButtonExportExcel from '../Button/ButtonExportExcel';
import { api, config } from '../../configs';
import ExcelSheetGenerator from './ExcelSheetGenerator';

const ExportExcelContainer = ({
  currentData,
  dataAPIEndPoint,
  dataQuery = {},
  sheetName,
  columnList,
  disableAllExport = false,
  buttonSize = 'medium',
  spreadedColumn = '',
}) => {
  const [dialogOpen, setDialogOpen] = useState();
  const [excelData, setExcelData] = useState();

  const handleAllDownload = async () => {
    try {
      if (dataQuery && !dataQuery?.size) {
        // eslint-disable-next-line no-param-reassign
        dataQuery.size = config.maxFetchSize;
      }
      const searchParam = new URLSearchParams(dataQuery);
      setDialogOpen(true);
      const { data } = await api.get(
        `${
          process.env.REACT_APP_API_URL
        }/${dataAPIEndPoint}/?${searchParam.toString()}`,
      );

      setExcelData(data?.rows);
      setDialogOpen(false);
    } catch (error) {
      alert(`ไม่สามารถดาวน์โหลดไฟล์ Excel ได้ ${error?.message}`);
    }
  };

  const handleCurrentDownload = () => {
    setExcelData(currentData);
  };

  useEffect(() => {
    let interval = () => {};
    if (excelData) {
      interval = setInterval(() => {
        setExcelData();
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [excelData]);

  return (
    <div>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <div className="flex justify-center">
            <CircularProgress />
          </div>
          <div>กำลังเตรียมข้อมูลสำหรับการดาวน์โหลด</div>
        </DialogContent>
      </Dialog>
      {excelData ? (
        <ExcelSheetGenerator
          columnList={columnList}
          dataSets={excelData}
          sheetName={sheetName}
          spreadedColumn={spreadedColumn}
        />
      ) : (
        <div></div>
      )}
      <ButtonExportExcel
        handleAllDownload={handleAllDownload}
        handleCurrentDownload={handleCurrentDownload}
        disableAllExport={disableAllExport}
        buttonSize={buttonSize}
      />
    </div>
  );
};

ExportExcelContainer.propTypes = {
  currentData: PropTypes.arrayOf(PropTypes.object),
  dataAPIEndPoint: PropTypes.string,
  dataQuery: PropTypes.object,
  sheetName: PropTypes.string,
  columnList: PropTypes.arrayOf(PropTypes.object),
  disableAllExport: PropTypes.bool,
  buttonSize: PropTypes.string,
  spreadedColumn: PropTypes.string,
};

export default ExportExcelContainer;
