import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import _ from 'lodash';

const THBText = require('thai-baht-text');

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (data) => {
  const product = _.map(data, (each, index) => [
    {
      text: index + 1,
    },
    {
      text: `${each?.product?.name} `,
    },
    {
      text: each?.option?.name || '-',
    },
    {
      text: each?.quantity || '-',
      alignment: 'right',
    },
    {
      text: (each?.price * each?.quantity)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
  ]);
  return product;
};

const InvoicePrint = async (paymentTransaction, setting, ArrayProductList) => {
  const genItemBody = genBody(ArrayProductList);
  console.log('PDF ArrayProductList', ArrayProductList);
  const address = setting?.owner?.address;
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'Portrait',
    pageMargins: [20, 180, 20, 190],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      {
        margin: [20, 20, 20, 10],
        table: {
          widths: ['*', '45%'],
          body: [
            [
              {
                text: 'ใบเสร็จรับเงิน RECEIPT',
                style: 'header',
                border: [false, false, false, false],
              },
              {
                text: `${setting?.owner?.name}`,
                border: [false, false, false, false],
                style: 'header',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: `${address?.house_number || ''} ถนน ${
                  address?.road || ''
                } หมู่ ${address?.village_number || ''} ตำบล${
                  address?.subdistrict || ''
                } อำเภอ${address?.district || ''} จังหวัด${
                  address?.province || ''
                } ${address?.postcode || ''}   `,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, true],
              },
              {
                text: `เลขประจำตัวผู้เสียภาษี ${setting?.owner?.tax}`,
                border: [false, false, false, true],
                margin: [0, 0, 0, 5],
              },
            ],
          ],
        },
      },
      {
        margin: [20, -2, 20, 20],
        table: {
          widths: ['55%', '45%'],
          body: [
            [
              {
                text: 'ชื่อผู้สั่งซื้อ',
                style: 'bodyHeader',
                border: [false, false, false, false],
              },
              {
                text: 'เลขที่ใบสั่งซื้อ',
                border: [false, false, false, false],
                style: 'bodyHeader',
              },
            ],
            [
              {
                text: `${paymentTransaction?.customer?.name}`,
                style: '',
                border: [false, false, false, false],
              },
              {
                text: `${paymentTransaction?.order_id?.prefix}`,
                border: [false, false, false, false],
                style: '',
              },
            ],
            [
              {
                text: '',
                style: '',
                border: [false, false, false, false],
                rowSpand: 2,
              },
              {
                text: 'วันเดือนปีที่สั่งซื้อ',
                border: [false, false, false, false],
                style: 'bodyHeader',
              },
            ],
            [
              {
                text: '',
                style: '',
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(paymentTransaction?.createdAt).format(
                  'DD MMM BBBB',
                )}  ${dayjs(paymentTransaction?.createdAt).format('HH:mm')}`,
                border: [false, false, false, false],
                style: '',
              },
            ],
          ],
        },
      },
    ],

    content: [
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['5%', '50%', '15%', '15%', '15%'],

          body: [
            [
              {
                fillColor: '#BFC1C0',
                text: 'ลำดับ',
                style: 'tableHeader',
                border: [true, true, true, true],
              },
              {
                fillColor: '#BFC1C0',
                text: 'สินค้า',
                style: 'tableHeader',
                border: [true, true, true, true],
              },
              {
                fillColor: '#BFC1C0',
                text: 'ขนาด',
                style: 'tableHeader',
                border: [true, true, true, true],
              },
              {
                fillColor: '#BFC1C0',
                text: 'จำนวน',
                border: [true, true, true, true],
                style: 'tableHeader',
              },
              {
                fillColor: '#BFC1C0',
                text: 'ราคารวม',
                border: [true, true, true, true],
                style: 'tableHeader',
              },
            ],
            ...genItemBody,
            !paymentTransaction.order_id.provider?.free_delivery ||
            paymentTransaction.order_id.provider?.provider?.more_price
              ?.available
              ? [
                  {
                    text: '',
                  },
                  {
                    text: 'ค่าจัดส่ง/ค่าดำเนินการ ',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                    alignment: 'right',
                  },
                  {
                    text: `${(paymentTransaction?.order_id?.delivery_price || 0)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: ' ',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                    alignment: 'right',
                  },
                ],
          ],
        },
      },
    ],

    footer: [
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',
          widths: ['75.33%', '*', '12.33%'],
          body: [
            [
              {
                fillColor: '#BFC1C0',
                text: `${THBText(paymentTransaction?.amount)}`,
                border: [false, false, false, false],
                alignment: 'center',
                style: '',
                bold: true,
              },
              {
                fillColor: '#BFC1C0',
                text: 'จำนวนเงินสุทธิ',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${(paymentTransaction?.amount || 0)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                alignment: 'right',
                bold: true,
              },
            ],
            [
              {
                text: [{ text: 'หมายเหตุ\n', bold: true }, 'note'],
                bold: true,
                rowSpan: 2,

                border: [false, false, false, false],
              },

              {
                fillColor: '#BFC1C0',
                text: 'ภาษีมูลค่าเพิ่ม',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${(paymentTransaction?.amount * 0.07)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                alignment: 'right',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                fillColor: '#BFC1C0',
                text: 'รวมราคาสินค้า',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${(
                  paymentTransaction?.amount -
                  paymentTransaction?.amount * 0.07
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                alignment: 'right',
              },
            ],
          ],
        },
      },
      {
        margin: [20, 15, 20, 0],
        alignment: 'center',
        table: {
          alignment: '',
          heights: 70,
          widths: ['33.33%', '*', '33.33%'],
          body: [
            [
              {
                text: [
                  { text: '....................................\n' },
                  { text: 'ผู้รับเงิน/Bill Receiver Signature\n' },
                  { text: 'วันที่/date............................\n' },
                ],
                margin: [0, 35, 0, 0],
                alignment: 'center',

                border: [true, true, true, true],
              },
              {
                text: 'ตราบริษัท',
                alignment: 'center',
                margin: [0, 55, 0, 0],
                border: [true, true, true, true],
              },
              {
                text: [
                  { text: '....................................\n' },
                  { text: 'ผู้มีอำนาจลงนาม/Authorized Signature \n' },
                  { text: 'วันที่/date............................\n' },
                ],
                margin: [0, 35, 0, 0],
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
      bodyHeader: {
        bold: true,
      },
      tableHeader: {
        bold: true,
        alignment: 'center',
      },
      name: {
        fontSize: 16,
        bold: true,
      },
      tab: {
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default InvoicePrint;
