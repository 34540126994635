import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EditIcon from '@mui/icons-material/Edit';

import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/no-duplicates
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
// eslint-disable-next-line import/no-duplicates
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const AccordionDetailsNew = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#ffffff',
}));

export default function DetailProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const history = useHistory();
  const serialNumber = useSelector((state) => state.serialNumber);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');

  const [expandedDetail, setExpandedDetail] = useState(false);

  const handleChangeExpanded = () => {
    setExpandedDetail(!expandedDetail);
  };

  useEffect(() => {
    dispatch(actions.productGet(id));
    dispatch(actions.getSerialOfProduct({ id, name, page, size }));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const { rows } = serialNumber;

  useEffect(() => {
    setTotal(serialNumber.total);
    return () => {};
  }, [serialNumber]);

  console.log('serialNumber', serialNumber);
  // eslint-disable-next-line no-shadow
  const handlePushToEditProduct = async (id) => {
    history.push(`/wms/product/edit/${id}`);
  };

  // eslint-disable-next-line no-underscore-dangle
  console.log('product', product);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDeleteSerial = async (serialId) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.deleteOneSerial(serialId));
        dispatch(actions.productGet(id));
        dispatch(actions.getSerialOfProduct({ id, name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const checkHtml = (detail) => {
    try {
      return parse(detail);
    } catch (error) {
      return '-';
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> Serial Number</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(rows) ? (
                rows.map((serial, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {((page || 1) - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{serial?.serialNumber}</TableCell>
                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => handleDeleteSerial(serial?.id)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  key={''}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className="bg-gray-100"
                >
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มี SN ที่ต้องการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const displayOption = () => (
    <div className="w-full">
      <div className="w-full py-4 pl-2 font-semibold ">ออฟชั่นเสริม</div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อออฟชั่น</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวนคงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคาต้นทุน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคาขาย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.options.map((_option, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={index % 2 === 0 ? 'bg-gray-100' : ''}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="my-auto">{_option?.name}</div>
                  </TableCell>
                  <TableCell>
                    <div className="my-auto">
                      {_option?.inventory} {product?.unit}
                    </div>
                  </TableCell>
                  <TableCell>
                    {`${parseInt(_option?.cost, 10 || 0, 10).toLocaleString(
                      undefined,
                    )}`}{' '}
                    {'บาท'}
                  </TableCell>
                  <TableCell>
                    {`${parseInt(_option?.price, 10 || 0, 10).toLocaleString(
                      undefined,
                    )}`}{' '}
                    {'บาท'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">
            <div className="flex flex-row justify-end pb-4">
              <div>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    // eslint-disable-next-line no-underscore-dangle
                    handlePushToEditProduct(product._id);
                  }}
                >
                  แก้ไข
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลสินค้า</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'รหัสสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${product?.type_code}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${product?.name}`}</div>

              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'จำนวนคงเหลือ'}
              </div>
              <div className="w-1/2 py-4 ">{`${product?.inventory}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'หน่วย'}</div>
              <div className="w-1/2 py-4 ">{`${product?.unit}`}</div>
              {!product?.option_type && (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ราคาต่อหน่วย'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    {`${(product?.price || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}{' '}
                    {' บาท'}
                  </div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ราคาต้นทุน'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    {`${(product?.cost_price || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}{' '}
                    {' บาท'}
                  </div>
                </>
              )}

              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${product?.product_type?.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ร้านค้า'}</div>
              <div className="w-1/2 py-4 ">{`${
                product?.brand?.name || '-'
              }`}</div>
              {product?.net ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ปริมาตรสุทธิ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${product?.net} ${product?.unit_net}`}</div>
                </>
              ) : (
                <></>
              )}
              {product?.net ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ขนาดบรรจุ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${product?.packing_size} ${product?.packing_unit}`}</div>
                </>
              ) : (
                <></>
              )}
              {product?.minimum_inventory_size ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'จำนวนสินค้าขั้นต่ำในการแจ้งเตือน'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${product?.minimum_inventory_size} ${product?.unit}`}</div>
                </>
              ) : (
                <></>
              )}
              {product?.option_type && <>{displayOption()}</>}

              {product?.images ? (
                <>
                  <div className="w-full py-4 px-2 font-semibold ">รูปภาพ</div>
                  <div className="w-full flex">
                    {_.map(product.images, (eachImage, index) => (
                      <div key={index} className="">
                        <img
                          key={index}
                          src={eachImage?.url}
                          className="w-64 my-auto "
                        />
                        <div className="my-4 flex justify-center  "></div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
              {!_.isEmpty(product?.files) ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    เอกสารแนบ
                  </div>
                  <div className="w-1/2 py-4">
                    <ul className="list-disc list-inside mx-2 w-full">
                      {_.map(product?.files, (_file, index) => (
                        <div className="flex">
                          {_file?.file_name ? (
                            <a
                              href={_file?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="my-auto "
                            >
                              <li
                                className="font-system my-auto hover:underline"
                                key={index}
                              >
                                {_file?.file_name}
                              </li>
                            </a>
                          ) : (
                            <li className="font-system my-auto" key={index}>
                              {_file?.path}
                            </li>
                          )}
                        </div>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="w-full ">
                <Accordion
                  expanded={expandedDetail}
                  onChange={handleChangeExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="px-2 font-semibold">รายละเอียดสินค้า</div>
                  </AccordionSummary>
                  <AccordionDetailsNew>
                    {product?.detail ? (
                      <div className="w-1/2 py-4 ">
                        {checkHtml(product?.detail)}
                      </div>
                    ) : (
                      <></>
                    )}
                  </AccordionDetailsNew>
                </Accordion>
              </div>

              {rows?.length === 0 ? (
                <></>
              ) : (
                <>
                  {' '}
                  <div className="w-full">
                    <div className="w-full py-4 px-2 font-semibold ">
                      {'รายการ Serial Number'}
                    </div>
                  </div>
                </>
              )}
            </div>
            {product.serial_type ? (
              <div>
                {renderSearch()}
                {renderTable()}
              </div>
            ) : (
              ''
            )}
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProduct.defaultProps = {
  title: '',
  subtitle: '',
};
