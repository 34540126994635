export const TRANSACTION_STATUS = {
  ORDER_WAIT: {
    status_code: 'ORDER_WAIT',
    description: 'รอการยืนยันคำสั่งซื้อจากร้านค้า',
    level: 0,
  },
  ORDER_CONFIRM: {
    status_code: 'ORDER_CONFIRM',
    description: 'ยืนยันคำสั่งซื้อ กำลังจัดเตรียมสินค้า',
    level: 1,
  },
  QUOTATION_SEND: {
    status_code: 'QUOTATION_SEND',
    description: 'ส่งใบเสนอราคาเรียบร้อย',
    level: 2,
  },
  PO_RECEIVE: {
    status_code: 'PO_RECEIVE',
    description: 'ได้รับใบ PO เรียบร้อย',
    level: 3,
  },
  PRODUCT_PREPARED: {
    status_code: 'PRODUCT_PREPARED',
    description: 'จัดเตรียมสินค้าเรียบร้อย รอการจัดส่ง',
    level: 4,
  },
  PRODUCT_TRANS: {
    status_code: 'PRODUCT_TRANS',
    description: 'สินค้ากำลังจัดส่งรอรับสินค้า',
    level: 5,
  },
  PRODUCT_COMPLETE: {
    status_code: 'PRODUCT_COMPLETE',
    description: 'สินค้าจัดส่งสำเร็จ ลูกค้าได้รับสินค้า',
    level: 6,
  },
  PRODUCT_CANCEL: {
    status_code: 'PRODUCT_CANCEL',
    description: 'ยกเลิกคำสั่งซื้อ',
    level: 7,
  },
};
export default TRANSACTION_STATUS;
