import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import dayjs from 'dayjs';

import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Fade,
  Modal,
  Backdrop,
  Autocomplete,
  Switch,
  FormControlLabel,
  Tooltip,
} from '@mui/material';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonIcon from '@mui/icons-material/Person';

import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';

import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import CustomersPOS from './createCustomerPOS';

const defaultValues = {
  type: '',
  organization_type: '',
  name: '',
  phone_number: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function FrontPage({ title, subtitle }) {
  // const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);

  const productType = useSelector((state) => state.productType);
  const product = useSelector((state) => state.product);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectProduct, setSelectProduct] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState('');

  const NewCustomer = _.map(customer.rows, (_customer, index) => ({
    index: index + 1,
    ..._customer,
  }));

  console.log('product', product?.rows);
  // const [hiddenSideBar, setHiddenSideBar] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [openModalOption, setOpenModalOption] = useState(false);
  const [openModalPaymentType, setOpenModalPaymentType] = useState(false);
  const [openModalAddCustomer, setOpenModalAddCustomer] = useState(false);

  const [openModalEditOrder, setOpenModalEditOrder] = useState(false);
  const [selectOption, setSelectOption] = useState();
  const [selectProductOrder, setSelectProductOrder] = useState();
  const [quantityEdit, setQuantityEdit] = useState(
    selectProductOrder?.quantity || 1,
  );
  const [detailMoreEdit, setDetailMoreEdit] = useState('');
  const [detailQuotation, setDetailQuotation] = useState('');
  const [discountPriceEdit, setDiscountPriceEdit] = useState();
  const [discountPercentEdit, setPercentPriceEdit] = useState();

  const [discountPriceBill, setDiscountPriceBill] = useState();
  const [discountPercentBill, setDiscountPercentBill] = useState();

  const [discountBill, setDiscountBill] = useState(0);
  const [selectPaymentType, setSelectPaymentType] = useState();
  const [selectCustomer, setSelectCustomer] = useState();
  const [invoice, setInvoice] = useState(false);

  const handleChangeInvoice = (event) => {
    setInvoice(event.target.checked);
  };

  console.log('orderList', orderList);

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  // console.log(
  //   'sum price no discount',
  //   _.sumBy(orderList, (eachOrder) => eachOrder.price),
  // );

  // console.log(
  //   'discount',
  //   _.sumBy(
  //     orderList,
  //     (eachOrder) => (eachOrder?.discount || 0) * eachOrder.quantity,
  //   ),
  // );

  // console.log(
  //   'sum price discount',
  //   _.sumBy(
  //     orderList,
  //     (eachOrder) => eachOrder?.price_after_discount || eachOrder.price,
  //   ),
  // );

  const handleChangeDiscountPriceBill = (e) => {
    setDiscountPriceBill(e.target.value);
  };

  const handleChangeDiscountPercentBill = (e) => {
    setDiscountPercentBill(e.target.value);
  };

  const handleChangeDiscountPriceEdit = (e) => {
    setDiscountPriceEdit(e.target.value);
  };

  const handleChangeDiscountPercentEdit = (e) => {
    setPercentPriceEdit(e.target.value);
  };

  const handleCloseModalAddCustomer = (e) => {
    setOpenModalAddCustomer(e.target.value);
  };

  const handleCloseModalOption = () => {
    setOpenModalOption(false);
  };

  const handleCloseModalPaymentType = () => {
    setOpenModalPaymentType(false);
  };

  const handleCloseModalEditOrder = () => {
    setOpenModalEditOrder(false);
  };

  useEffect(() => {
    dispatch(actions.productAll({ name, page, size, selectProduct }));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.meGet());
    dispatch(actions.customerAll(''));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, [name, page, size, selectProduct]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangeType = (event) => {
    // console.log('type product', event.target);
    setSelectProduct(event.target.value);
  };

  const onSubmitAddCustomer = async (data, event) => {
    try {
      console.log('in');
      console.log('data', data);
      event.preventDefault();
      await dispatch(actions.customerCreate(data));
      reset(defaultValues);
      await dispatch(actions.customerAll(''));

      console.log('customer after add', customer?.rows[0]);
      // setSelectCustomer(customer?.rows[0]);
      // setOpenModalAddCustomer(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectOrder = async (productOrder) => {
    console.log('productOrder', productOrder);
    if (productOrder?.option_type) {
      setOpenModalOption(true);
      setSelectOption(productOrder);
    } else {
      const NewArray = [...orderList];
      NewArray.push({ ...productOrder, quantity: 1, index: NewArray.length });
      setOrderList(NewArray);
    }
  };

  const handleEditOrder = (index) => {
    console.log('index', index);
    const NewArray = [...orderList];
    setOpenModalEditOrder(true);

    // NewArray[index] = { ...NewArray[index], quantity: 2 };

    console.log('NewArray', NewArray);

    // NewArray.splice(index, 1);
    setOrderList(NewArray);
  };

  const onSubmitDiscountBill = () => {
    if (
      discountPriceBill !== '' &&
      discountPriceBill &&
      discountPriceBill !== '0'
    ) {
      console.log('discountPriceBill', parseFloat(discountPriceBill));
      setDiscountBill(parseFloat(discountPriceBill));
    } else if (
      discountPercentBill !== '' &&
      discountPercentBill &&
      discountPercentBill !== '0'
    ) {
      console.log('discountPercentBill', parseFloat(discountPercentBill));
      const ddddd = _.sumBy(
        orderList,
        (eachOrder) => eachOrder?.price_after_discount || eachOrder.price,
      );

      const fffff = (ddddd * parseFloat(discountPercentBill)) / 100;

      setDiscountBill(fffff);
    } else {
      console.log('no discount');
      setDiscountBill(0);
    }
  };

  useEffect(() => {
    onSubmitDiscountBill();
    return () => {};
  }, [discountPercentBill, discountPriceBill]);

  const onSubmitEditOrder = () => {
    console.log('quantityEdit', quantityEdit);
    console.log('discountPercentEdit', discountPercentEdit);
    console.log('discountPriceEdit', discountPriceEdit);
    console.log('detailMoreEdit', detailMoreEdit);
    console.log('selectProductOrder', selectProductOrder);
    console.log('discountPercentBill', discountPercentBill);
    console.log('discountPriceBill', discountPriceBill);

    const NewArray = [...orderList];

    if (
      discountPriceEdit !== '' &&
      discountPriceEdit &&
      discountPriceEdit !== '0'
    ) {
      console.log('discountPriceEdit');
      const updateArray = NewArray.map((eachOrder) => {
        if (eachOrder.index === selectProductOrder.index) {
          const payload = {
            ...eachOrder,
            remark: detailMoreEdit,
            quantity: quantityEdit,
            discount: parseFloat(discountPriceEdit),
            price_after_discount:
              selectProductOrder.price - parseFloat(discountPriceEdit),
          };
          return payload;
        }
        return eachOrder;
      });

      console.log('updateArray', updateArray);
      setOrderList(updateArray);
      setOpenModalEditOrder(false);
    } else if (
      discountPercentEdit !== '' &&
      discountPercentEdit &&
      discountPercentEdit !== '0'
    ) {
      console.log('discountPercentEdit');
      const updateArray = NewArray.map((eachOrder) => {
        if (eachOrder.index === selectProductOrder.index) {
          const payload = {
            ...eachOrder,
            remark: detailMoreEdit,
            quantity: quantityEdit,
            discount:
              (parseFloat(discountPercentEdit) * selectProductOrder.price) /
              100,
            price_after_discount:
              selectProductOrder.price -
              (parseFloat(discountPercentEdit) * selectProductOrder.price) /
                100,
          };
          return payload;
        }
        return eachOrder;
      });

      console.log('updateArray', updateArray);
      setOrderList(updateArray);
      setOpenModalEditOrder(false);
    } else {
      console.log('no discount');
      const updateArray = NewArray.map((eachOrder) => {
        if (eachOrder.index === selectProductOrder.index) {
          const payload = {
            ...eachOrder,
            remark: detailMoreEdit,
            quantity: quantityEdit,
            discount: undefined,
            price_after_discount: undefined,
          };
          return payload;
        }
        return eachOrder;
      });

      console.log('updateArray', updateArray);
      setOrderList(updateArray);
      setOpenModalEditOrder(false);
    }
  };

  const handleChangeDetailEdit = (e) => {
    setDetailMoreEdit(e.target.value);
  };

  const handleChangeDetailQuotation = (e) => {
    setDetailQuotation(e.target.value);
  };

  const handleIncreaseQuantityEdit = () => {
    if (!(quantityEdit === 1)) {
      setQuantityEdit(quantityEdit - 1);
    }
  };

  const handleDecreaseQuantityEdit = () => {
    // if (quantityEdit + 1 > products?.inventory && !products?.stock_infinite) {
    //   alert("ไม่สามารถเพิ่มสินค้าได้มากกว่านี้");
    // } else {
    setQuantityEdit(quantityEdit + 1);
    // }
  };

  const handleChangeQuantityEdit = (e) => {
    if (!(e.target.value < 0)) {
      setQuantityEdit(e.target.value);
    }
  };

  const handleRemoveOrder = (index) => {
    const NewArray = [...orderList];
    // eslint-disable-next-line react/prop-types
    NewArray.splice(index, 1);
    setOrderList(NewArray);
  };

  const onSubmitPOS = async () => {
    if (_.isEmpty(orderList)) {
      alert('ยังไม่มีรายการสินค้า');
    } else {
      console.log('orderList', orderList);

      const newOrderList = _.map(orderList, (eachOrder) => ({
        // eslint-disable-next-line no-underscore-dangle
        product: eachOrder?.id,
        // eslint-disable-next-line no-underscore-dangle
        option: eachOrder?.option_select?._id,
        amount: eachOrder?.quantity,
        price: eachOrder?.price,
        cost_price: eachOrder?.cost_price || eachOrder?.price,
        discount: eachOrder?.discount,
        remark: eachOrder?.remark,
      }));

      const preprocessedData = {
        customer: selectCustomer?.id,
        quotation: invoice,
        product_transaction_type: 'pos',
        order: newOrderList,
        bill_no: invoice
          ? `QT${new Date().valueOf()}`
          : `POS${new Date().valueOf()}`,
        date: dayjs(new Date()).locale('th').format('YYYY-MM-DD HH:mm:ss'),
        employee: me?.userData?.id,
        sale_type: 'storefront',
        payment_type: selectPaymentType,
        discount_bill: parseFloat(discountBill),
        discount_bill_percent: discountPercentBill,
        discount_bill_price: discountPriceBill,
        remark: detailQuotation,
      };

      console.log('preprocessedData', preprocessedData);

      await dispatch(actions.productTransactionCreate(preprocessedData));
      alert('สำเร็จ');

      setOrderList([]);
      setPercentPriceEdit();
      setDiscountPriceEdit();
      setDetailMoreEdit('');
      setQuantityEdit();
      setSelectProductOrder();
      setSelectOption();
      setOpenModalPaymentType(false);
      setSelectCustomer();

      await dispatch(actions.productAll({ name, page, size, selectProduct }));
      await dispatch(actions.productTypeAll(''));
    }
  };

  function toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderFullScreenButton = () => (
    <div className="flex">
      <div className="mr-2">
        <FormControlLabel
          control={<Switch checked={invoice} onChange={handleChangeInvoice} />}
          label="ออกใบเสนอราคา"
        />
        <Tooltip
          title="กดเปิด กรณีต้องการออกใบเสนอราคา ซึ่งระบบจะยังไม่ทำการตัดสต๊อคสินค้า"
          placement="top"
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className="mr-2">
        <Button
          variant="contained"
          onClick={() => setOpenModalAddCustomer(true)}
          startIcon={<PersonIcon />}
        >
          ลูกค้า
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={() => toggleFullScreen()}
          startIcon={<FullscreenIcon />}
        >
          เต็มจอ
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-2 md:flex md:flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 md:pl-1 pt-2 md:pt-0">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ประเภทสินค้า
            </InputLabel>
            <Select
              label="ประเภทสินค้า"
              size={'small'}
              value={selectProduct}
              onChange={handleChangeType}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(productType?.rows) ? (
                _.map(productType?.rows, (row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const renderModalEditProduct = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalEditOrder}
      onClose={handleCloseModalEditOrder}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalEditOrder}>
        <Card sx={style} className="max-w-2xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-3xl underline text-center my-4">
              แก้ไขรายการสินค้า
            </div>
            {/* เพิ่มลดจำนวน */}
            <div className="w-full flex justify-between mt-2">
              <div className="my-auto text-2xl">จำนวน</div>
              <div className="flex">
                <IconButton
                  color="primary"
                  component="span"
                  size="large"
                  onClick={handleIncreaseQuantityEdit}
                >
                  <RemoveIcon />
                </IconButton>
                <div className="my-auto">
                  <TextField
                    onChange={(e) => handleChangeQuantityEdit(e)}
                    sx={{
                      width: '90px',
                      textAlign: 'center',
                    }}
                    variant="outlined"
                    size="large"
                    value={quantityEdit}
                    defaultValue={selectProductOrder?.quantity}
                  />
                </div>

                <IconButton
                  color="primary"
                  component="span"
                  size="large"
                  onClick={handleDecreaseQuantityEdit}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            <div className="py-2 text-2xl">เพิ่มส่วนลด</div>
            <div className="w-full flex mb-2">
              <div className="pt-2 w-2/5">
                <TextField
                  onChange={(e) => handleChangeDiscountPriceEdit(e)}
                  sx={{
                    width: '100%',
                  }}
                  fullWidth
                  type="number"
                  value={discountPriceEdit}
                  disabled={discountPercentEdit}
                  variant="outlined"
                  label="จำนวนเงิน"
                />
              </div>
              <div className="pt-2 w-1/5 text-xl text-center my-auto">หรือ</div>
              <div className="pt-2 w-2/5">
                <TextField
                  onChange={(e) => handleChangeDiscountPercentEdit(e)}
                  sx={{
                    width: '100%',
                  }}
                  fullWidth
                  type="number"
                  value={discountPercentEdit}
                  disabled={discountPriceEdit}
                  variant="outlined"
                  label="เปอร์เซ็นต์"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="mb-2 text-red-600">* กรุณาเลือกแค่ 1 ช่องทาง</div>
            <div className="py-2 text-2xl w-full">หมายเหตุ</div>
            <div className="pt-2 mb-2">
              <TextField
                onChange={(e) => handleChangeDetailEdit(e)}
                sx={{
                  width: '100%',
                }}
                variant="outlined"
                label="หมายเหตุ"
                multiline
                rows={3}
                maxRows={5}
              />
            </div>

            <div className="flex gap-1">
              <div
                className="w-2/3 text-center text-2xl  bg-yellow-300 py-4 text-white cursor-pointer hover:bg-yellow-400"
                onClick={() => onSubmitEditOrder()}
              >
                บันทึก
              </div>
              <div className="w-1/3">
                <Button
                  variant="outlined"
                  onClick={() => setOpenModalEditOrder(false)}
                  fullWidth
                >
                  <div className="py-3 text-2xl">ปิด</div>
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalOptionSelect = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalOption}
      onClose={handleCloseModalOption}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalOption}>
        <Card sx={style} className="max-w-3xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-2xl text-center underline my-2">
              เลือกออฟชั่นของสินค้า
            </div>
            <div className="mt-2 grid grid-cols-4 gap-2">
              {_.map(selectOption?.options, (eachOption) => (
                <div
                  className="rounded-md border-2 border-gray-500 p-2 cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    const NewArray = [...orderList];
                    NewArray.push({
                      ...selectOption,
                      // eslint-disable-next-line no-underscore-dangle
                      option_select: eachOption,
                      price: eachOption?.price,
                      quantity: 1,
                      index: NewArray.length,
                    });
                    setOrderList(NewArray);
                    setOpenModalOption(false);
                  }}
                >
                  <div className="text-center text-xl">{eachOption?.name}</div>
                  <div className="text-center font-bold text-xl text-yellow-500 mt-4">
                    ฿{' '}
                    {(eachOption?.price)
                      .toFixed(1)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalAddCustomer = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalAddCustomer}
      onClose={handleCloseModalAddCustomer}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalAddCustomer}>
        <Card sx={style} className="max-w-2xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-2xl text-center underline">
              เลือกลูกค้า
            </div>
            <div className="my-2">
              <Autocomplete
                onChange={(event, newValue) => {
                  setSelectCustomer(newValue);
                }}
                fullWidth
                options={NewCustomer}
                getOptionLabel={(option) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  `${option?.index} : ${option?.name}`
                }
                renderInput={(params) => (
                  <TextField {...params} label="ลูกค้า" />
                )}
              />
            </div>
            <div className="text-2xl text-center pt-2 underline">หรือ</div>
            <form onSubmit={handleSubmit(onSubmitAddCustomer)}>
              <CustomersPOS
                control={control}
                Controller={Controller}
                customerType={customerType}
                selectCustomer={selectCustomer}
              />
              <div className="flex gap-1">
                <div className="w-2/3">
                  {selectCustomer ? (
                    <Button
                      variant="contained"
                      onClick={() => setOpenModalAddCustomer(false)}
                      fullWidth
                    >
                      <div className="py-3 text-2xl">บันทึก</div>
                    </Button>
                  ) : (
                    <Button variant="contained" type="submit" fullWidth>
                      <div className="py-3 text-2xl">บันทึก</div>
                    </Button>
                  )}
                </div>
                <div className="w-1/3">
                  <Button
                    variant="outlined"
                    onClick={() => setOpenModalAddCustomer(false)}
                    fullWidth
                  >
                    <div className="py-3 text-2xl">ปิด</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalPaymentType = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalPaymentType}
      onClose={handleCloseModalPaymentType}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalPaymentType}>
        <Card sx={style} className="max-w-4xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="w-full flex">
              <div className="w-3/5 border-r-2 border-gray-400 pr-4">
                <div className="py-2 text-2xl text-center underline my-2">
                  ส่วนลดท้ายบิล
                </div>
                <div className="mb-2 text-red-600">* กรุณาใส่แค่ 1 ช่องทาง</div>
                <div className="w-full flex mb-2">
                  <div className="pt-2 w-2/5">
                    <TextField
                      onChange={(e) => handleChangeDiscountPriceBill(e)}
                      sx={{
                        width: '100%',
                      }}
                      fullWidth
                      type="number"
                      value={discountPriceBill}
                      disabled={discountPercentBill}
                      variant="outlined"
                      label="จำนวนเงิน"
                    />
                  </div>
                  <div className="pt-2 w-1/5 text-xl text-center my-auto">
                    หรือ
                  </div>
                  <div className="pt-2 w-2/5">
                    <TextField
                      onChange={(e) => handleChangeDiscountPercentBill(e)}
                      sx={{
                        width: '100%',
                      }}
                      fullWidth
                      type="number"
                      value={discountPercentBill}
                      disabled={discountPriceBill}
                      variant="outlined"
                      label="เปอร์เซ็นต์"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className="py-2 text-2xl text-center underline">
                  เลือกวิธีการชำระเงิน
                </div>
                <div className="grid grid-cols-2 mt-4 gap-2">
                  <div
                    className={`text-center text-2xl rounded-md border-2 border-gray-600 py-10 cursor-pointer hover:bg-gray-200 ${
                      selectPaymentType === 'cash' && 'bg-gray-300'
                    }`}
                    onClick={() => setSelectPaymentType('cash')}
                  >
                    <div>เงินสด</div>
                    <div>CASH</div>
                  </div>
                  <div
                    className={`text-center text-2xl rounded-md border-2 border-gray-600 py-10 cursor-pointer hover:bg-gray-200 ${
                      selectPaymentType === 'transfer' && 'bg-gray-300'
                    }`}
                    onClick={() => setSelectPaymentType('transfer')}
                  >
                    <div>โอน</div>
                    <div>TRANSFER</div>
                  </div>
                </div>

                <div className="py-2 text-2xl text-center underline">
                  หมายเหตุ
                </div>
                <div className="pt-2 mb-2">
                  <TextField
                    onChange={(e) => handleChangeDetailQuotation(e)}
                    sx={{
                      width: '100%',
                    }}
                    variant="outlined"
                    label="หมายเหตุ"
                    multiline
                    rows={3}
                    maxRows={5}
                  />
                </div>

                <div className="flex gap-1">
                  <div
                    className="w-2/3 text-center text-2xl mt-4 bg-yellow-300 py-4 text-white cursor-pointer hover:bg-yellow-400"
                    onClick={() => onSubmitPOS()}
                  >
                    บันทึก
                  </div>

                  <div className="w-1/3 mt-4">
                    <Button
                      variant="outlined"
                      onClick={() => setOpenModalPaymentType(false)}
                      fullWidth
                    >
                      <div className="py-3 text-2xl">ปิด</div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="w-2/5">
                <div className="ml-2">
                  <div className="mt-3 py-2 px-4 flex items-center justify-between">
                    <span className="font-semibold text-2xl">ยอดสุทธิ</span>
                    <span className="font-bold text-2xl">
                      ฿{' '}
                      {(
                        _.sumBy(
                          orderList,
                          (eachOrder) =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            (eachOrder?.price_after_discount ||
                              eachOrder.price) * eachOrder.quantity,
                        ) - parseFloat(discountBill)
                      )
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </span>
                  </div>
                  <div className=" px-4 flex justify-between ">
                    <span className="font-semibold">ส่วนลดท้ายบิล</span>
                    <span className="font-bold">
                      - ฿{' '}
                      {parseFloat(discountBill)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  if (product.isLoading || customer.isLoading || productType.isLoading) {
    return <Loading />;
  }
  if (
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !productType.isLoading &&
    productType.isCompleted
  ) {
    return (
      <div>
        {renderModalOptionSelect()}
        {renderModalEditProduct()}
        {renderModalPaymentType()}
        {renderModalAddCustomer()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="my-auto">{renderFullScreenButton()}</div>
        </div>
        {renderSearch()}
        <div className="mt-2 mx-auto bg-white">
          <div className="flex lg:flex-row flex-col-reverse shadow-lg">
            {/* left section */}
            <div className="w-full lg:w-3/4 max-h-screen shadow-lg">
              {/* products */}
              <div className="grid grid-cols-4 gap-4 px-5 mt-5 overflow-y-auto h-5/6">
                {_.map(product?.rows, (eachProduct, index) => (
                  <>
                    {eachProduct?.inventory === 0 &&
                    !eachProduct?.stock_infinite ? (
                      <div
                        key={index}
                        className="relative px-3 py-3 flex flex-col border border-gray-200  rounded-md h-40 justify-between cursor-pointer"
                      >
                        <div>
                          <div className="font-bold text-gray-400 truncate">
                            {eachProduct?.name}
                          </div>
                        </div>
                        <div className="flex flex-row justify-between items-center">
                          <span className="self-end font-bold text-xl text-yellow-500">
                            ฿{' '}
                            {(eachProduct?.price)
                              .toFixed(1)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </span>

                          {_.isEmpty(eachProduct?.images) ? (
                            <img
                              key={index}
                              src="../imageComing.jpg"
                              alt={eachProduct?.name}
                              className=" h-20 w-20 object-cover rounded-md"
                            ></img>
                          ) : (
                            <img
                              src={`${eachProduct?.images[0]?.url}`}
                              alt={eachProduct?.name}
                              className=" h-20 w-20 object-cover rounded-md"
                            />
                          )}
                        </div>
                        <div className="bg-gray-600 opacity-50  absolute top-0 left-0 w-full h-full rounded-md mx-auto">
                          <p className="bottom-8 left-2 text-xl text-white absolute">
                            สินค้าหมด
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="px-3 py-3 flex flex-col border border-gray-200 rounded-md h-40 justify-between cursor-pointer"
                        onClick={() => handleSelectOrder(eachProduct)}
                      >
                        <div>
                          <div className="font-bold text-gray-800 truncate">
                            {eachProduct?.name}
                          </div>
                          {/* <span className="font-light text-sm text-gray-400">
                        150g
                      </span> */}
                        </div>
                        <div className="flex flex-row justify-between items-center">
                          <span className="self-end font-bold text-xl text-yellow-500">
                            ฿{' '}
                            {(eachProduct?.price)
                              .toFixed(1)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </span>

                          {_.isEmpty(eachProduct?.images) ? (
                            <img
                              key={index}
                              src="../imageComing.jpg"
                              alt={eachProduct?.name}
                              className=" h-20 w-20 object-cover rounded-md"
                            ></img>
                          ) : (
                            <img
                              src={`${eachProduct?.images[0]?.url}`}
                              alt={eachProduct?.name}
                              className=" h-20 w-20 object-cover rounded-md"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              {/* end products */}
            </div>
            {/* end left section */}
            {/* right section */}
            <div className="w-full lg:w-1/4">
              {/* header */}

              {/* end header */}
              {/* totalItems */}
              <div className="px-2">
                <div className="flex justify-end text-xl mt-2">
                  {selectCustomer?.name || ''}
                </div>
                <div className="py-4 rounded-md shadow-lg">
                  <div className=" px-4 flex justify-between ">
                    <span className="font-semibold">ยอดรวม</span>
                    <span className="font-bold">
                      ฿{' '}
                      {_.sumBy(
                        orderList,
                        (eachOrder) => eachOrder.price * eachOrder.quantity,
                      )
                        .toFixed(1)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </span>
                  </div>
                  <div className=" px-4 flex justify-between ">
                    <span className="font-semibold">ส่วนลด</span>
                    <span className="font-bold">
                      - ฿{' '}
                      {(
                        _.sumBy(
                          orderList,
                          (eachOrder) =>
                            // eslint-disable-next-line implicit-arrow-linebreak
                            (eachOrder?.discount || 0) * eachOrder.quantity,
                        ) || 0
                      )
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </span>
                  </div>

                  {/* <div className=" px-4 flex justify-between ">
                    <span className="font-semibold">ภาษีการขาย</span>
                    <span className="font-bold">฿0.00</span>
                  </div> */}
                  <div className="border-t-2 mt-3 py-2 px-4 flex items-center justify-between">
                    <span className="font-semibold text-2xl">ยอดสุทธิ</span>
                    <span className="font-bold text-2xl">
                      ฿{' '}
                      {_.sumBy(
                        orderList,
                        (eachOrder) =>
                          // eslint-disable-next-line implicit-arrow-linebreak
                          (eachOrder?.price_after_discount || eachOrder.price) *
                          eachOrder.quantity,
                      )
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </span>
                  </div>
                  {/* <div className=" px-4 flex justify-between ">
                    <span className="font-semibold">ส่วนลดท้ายบิล</span>
                    <span className="font-bold">
                      - ฿{' '}
                      {parseFloat(discountBill)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </span>
                  </div> */}
                </div>
              </div>
              {/* end total */}

              {/* button pay */}
              <div className="px-2 my-4">
                <div
                  className="px-4 py-4 rounded-md shadow-lg text-center bg-yellow-500 text-white font-semibold cursor-pointer"
                  onClick={() => {
                    // onSubmitPOS();
                    setOpenModalPaymentType(true);
                  }}
                >
                  {invoice ? 'ออกใบเสนอราคา' : 'ชำระเงิน'}
                </div>
              </div>
              {/* order list */}
              <div className="flex flex-row items-center justify-between px-5 mt-5">
                <div className="font-bold text-xl">รายการสินค้า</div>
                <div className="font-bold text-xl">{orderList?.length}</div>
              </div>
              <div className="px-2 py-4 mt-5 overflow-y-auto h-96  border-4 border-dashed mx-2">
                {_.isEmpty(orderList) ? (
                  <div className="text-center">ไม่มีรายการ</div>
                ) : (
                  <>
                    {_.map(orderList, (eachOrder, index) => (
                      <div
                        key={index}
                        className="flex w-full rounded-md py-3 border-b cursor-pointer hover:bg-gray-200"
                      >
                        <div
                          className="flex w-4/5"
                          onClick={() => {
                            setSelectProductOrder(eachOrder);
                            handleEditOrder(index);
                          }}
                        >
                          <div className="w-1/3">
                            {_.isEmpty(eachOrder?.images) ? (
                              <img
                                key={index}
                                src="../imageComing.jpg"
                                alt={eachOrder?.name}
                                className=" h-12 w-12 object-cover rounded-md"
                              ></img>
                            ) : (
                              <img
                                src={`${eachOrder?.images[0]?.url}`}
                                alt={eachOrder?.name}
                                className=" h-12 w-12 object-cover rounded-md"
                              />
                            )}
                          </div>
                          <div className="w-2/3 px-1">
                            <div className="truncate text-gray-700 text-lg">
                              {eachOrder?.name}
                            </div>
                            {eachOrder?.option_select && (
                              <div className="truncate text-gray-700 text-lg">
                                ( {eachOrder?.option_select?.name} )
                              </div>
                            )}

                            {eachOrder?.price_after_discount ? (
                              <div className="flex text-red-600">
                                <div>
                                  <div className="text-red-600 text-lg ">
                                    {(eachOrder?.price_after_discount)
                                      .toFixed(1)
                                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                  </div>
                                  <div className="line-through text-sm text-gray-400">
                                    {(eachOrder?.price)
                                      .toFixed(1)
                                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                  </div>
                                </div>
                                <div className="px-1 text-lg ">x</div>
                                <div className="text-lg ">
                                  {eachOrder?.quantity}
                                </div>
                              </div>
                            ) : (
                              <div className="text-lg text-red-600">
                                {(eachOrder?.price)
                                  .toFixed(1)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                                x {eachOrder?.quantity}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-1/5">
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              handleRemoveOrder(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="w-full p-2">
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<DeleteIcon />}
                  onClick={() => setOrderList([])}
                >
                  <div className="text-base">
                    ลบทั้งหมด ({orderList?.length})
                  </div>
                </Button>
              </div>
              {/* end order list */}
            </div>
            {/* end right section */}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

FrontPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

FrontPage.defaultProps = {
  title: '',
  subtitle: '',
};

export default FrontPage;
