import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    color: '#fff',
  },
});

export default function Loading({ isLoading }) {
  const classes = useStyles();
  return (
    <div className="min-h-screen overflow-hidden">
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
        {/* <iframe src="https://embed.lottiefiles.com/animation/65358"></iframe>{' '} */}
      </Backdrop>
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: true,
};
