import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EditCustomerForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);

  console.log('customer', customer);
  const [addSupplier, setAddSupplier] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      type: customer.type,
      name: customer.name,
      organization_type: customer.organization_type,
      address: customer.address,
      taxes: customer.taxes,
      phone_number: customer.phone_number,
      fax: customer.fax,
      email: customer.email,
      payment: customer.payment,
      source: customer.source,
      bank_name: customer.bank_name,
      bank_account_name: customer.bank_account_name,
      bank_account_number: customer.bank_account_number,
    },
  });

  useEffect(() => {
    dispatch(actions.customerGet(id));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const dataEnd = {
        ...data,
        supplier: addSupplier,
      };
      console.log('data', dataEnd);
      await dispatch(actions.customerPut(id, dataEnd));
      await dispatch(actions.customerGet(id));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลลูกค้า</div>
        </div>
        <div className="flex flex-wrap">
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditCustomerForm
                errors={errors}
                customerType={customerType}
                customer={customer}
                control={control}
                Controller={Controller}
                addSupplier={addSupplier}
                setAddSupplier={setAddSupplier}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
