import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { ExportForm, IncreaseProductForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const defaultValues = {
  type_code: '',
  name: '',
  description: '',
};

const CreateExport = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  // const productType = useSelector((state) => state.productType);
  const employee = useSelector((state) => state.employee);
  // const productTransactionType = useSelector(
  //   (state) => state.productTransactionType,
  // );
  // const productTransaction = useSelector((state) => state.productTransaction);
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectSerial, setSelectSerial] = useState([]);
  const [selectProduct, setSelectProduct] = useState();

  console.log('productList', productList);

  const [discountPriceBill, setDiscountPriceBill] = useState();
  const [discountPercentBill, setDiscountPercentBill] = useState();

  const [discountBill, setDiscountBill] = useState(0);

  const {
    formState: { errors },
    handleSubmit,
    control,
    // watch,
    reset,
  } = useForm();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  // const [type, setType] = useState(null);
  const [filterProduct, setFilterProduct] = useState(null);
  // const [selectedProducts, setSelectedProducts] = useState(
  //   productTransaction?.order || [],
  // );
  const [invoice, setInvoice] = useState(false);
  const [defaultMe, setDefaultMe] = useState(me?.userData);
  // console.log('Selected Product 111', selectedProducts);

  const handleChangeInvoice = (event) => {
    setInvoice(event.target.checked);
  };

  useEffect(() => {
    dispatch(actions.productAll(''));
    dispatch(actions.employeeAll(''));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.productTransactionTypeAll(''));
    dispatch(actions.meGet());
    setDefaultMe(me?.userData);
    const fetchProcessTemplate = async () => {
      try {
        await dispatch(actions.productTransactionAll({ name, page, size, me }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchCustomers = async () => {
      try {
        await dispatch(actions.customerAll(''));
      } catch (error) {
        console.error('Customers get all error', error);
      }
    };

    fetchCustomers();
    fetchProcessTemplate();

    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    console.log('productList', productList);
    if (productList?.length === 0) {
      alert('กรุณาเพิ่มสินค้าก่อน');
    } else {
      try {
        if (data.employee) {
          const preprocessedData = {
            ...data,
            product_transaction_type: 'ออก',
            order: _.map(productList, (each) => ({
              // eslint-disable-next-line no-underscore-dangle
              product: each.product._id,
              amount: each.inventory,
              price: each.price,
              serial:
                _.map(each.serials, (Serial) => ({
                  serialNumber: Serial.id,
                  name: Serial.name,
                })) || null,
            })),
          };
          console.log('Data', data);
          console.log('preprocessedData', preprocessedData);
          event.preventDefault();
          await dispatch(actions.productTransactionCreate(preprocessedData));
          reset(defaultValues);
          alert('สำเร็จ');

          await dispatch(actions.productTransactionAll({ name, page, size }));
          history.goBack();
        } else {
          // eslint-disable-next-line no-lonely-if
          const preprocessedData = {
            ...data,
            employee: { ...defaultMe, _id: defaultMe?.id },
            product_transaction_type: 'ออก',
            order: _.map(productList, (each) => ({
              // eslint-disable-next-line no-underscore-dangle
              product: each.product._id,
              amount: each.inventory,
              price: each.price,
              serial:
                _.map(each.serials, (Serial) => ({
                  serialNumber: Serial.id,
                  name: Serial.name,
                })) || null,
            })),
          };
          console.log('Data', data);
          console.log('preprocessedData', preprocessedData);
          event.preventDefault();
          await dispatch(actions.productTransactionCreate(preprocessedData));
          reset(defaultValues);
          alert('สำเร็จ');

          await dispatch(actions.productTransactionAll({ name, page, size }));
          history.goBack();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDeleteProduct = (index) => {
    productList.splice(index, 1);
    setProductList(productList);
    setReload(!reload);
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <ExportForm
        Controller={Controller}
        control={control}
        errors={errors}
        invoice={invoice}
        handleChangeInvoice={handleChangeInvoice}
        customers={customer.rows}
        // product={product.rows}
        // productType={productType.rows}
        employees={employee.rows}
        // productTransactionType={productTransactionType.rows}
        // selectedProducts={selectedProducts}
        // setSelectedProducts={setSelectedProducts}
        // type={type}
        // setType={setType}
        // selectProduct={selectProduct}
        // setSelectProduct={setSelectProduct}
        // filterProduct={filterProduct}
        // setFilterProduct={setFilterProduct}
        // online={online}
        // setOnline={setOnline}
        me={me}
      />
    </Card>
  );

  const renderAddProductForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มสินค้า</div>
      <IncreaseProductForm
        Controller={Controller}
        // control={control}
        errors={errors}
        // watch={watch}
        customers={customer.rows}
        product={product.rows}
        // productType={productType.rows}
        employees={employee.rows}
        // productTransactionType={productTransactionType.rows}
        // selectedProducts={selectedProducts}
        // setSelectedProducts={setSelectedProducts}
        // type={type}
        // setType={setType}
        selectProduct={selectProduct}
        setSelectProduct={setSelectProduct}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        productList={productList}
        setProductList={setProductList}
        reload={reload}
        setReload={setReload}
        selectSerial={selectSerial}
        setSelectSerial={setSelectSerial}
      />
    </Card>
  );

  const displayProduct = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ราคาต่อหน่วย</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(productList) ? (
            productList.map((_product, index) => (
              <>
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{_product?.product.name}</TableCell>
                  <TableCell>{_product?.inventory}</TableCell>
                  <TableCell>{_product?.price}</TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => handleDeleteProduct(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>ยอดเงินก่อนหักส่วนลด</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">1000</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>ส่วนลด</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">20 %</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>ยอดเงินหลังหักส่วนลด</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">800</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>จำนวนเงินทั้งสิ้น</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">800</div>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderShowProductList = () => (
    <Card className="p-6">
      <div className="py-2">รายการสินค้า</div> {displayProduct()}
    </Card>
  );

  if (customer.isLoading || product.isLoading || employee.isLoading) {
    return <Loading />;
  }
  if (
    !customer.isLoading &&
    customer.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !employee.isLoading &&
    employee.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">สร้างการส่งออกสินค้า</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex">
            <div className="w-full md:w-1/3 pr-4">{renderForm()}</div>
            <div className="w-full md:w-2/3 pt-4 md:pt-0">
              {renderAddProductForm()}
              <div className="w-full pt-4">{renderShowProductList()}</div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={customer?.message} />;
};

CreateExport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateExport.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateExport;
