import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

import qrcode from 'qrcode';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const CoverPrint = async (paymentTransaction, setting) => {
  // console.log('paymentTransaction in cover print', paymentTransaction);
  // console.log('setting in cover print', setting);

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 250,
  };

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };

  const documentRef = {
    pageSize: 'A5',
    pageOrientation: 'Landscape',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    content: [
      {
        margin: [0, 30],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['45%', '*', '45%'],

          body: [
            [
              {
                text: 'ที่อยู่ผู้ฝาก',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: `${setting?.owner?.name}`,
                style: 'name',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: `เบอร์โทร ${setting?.owner?.telephone}`,
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: 'ชื่อที่อยู่ผู้รับ',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: `คุณ ${paymentTransaction?.customer?.name}`,
                style: 'name',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: `บ้านเลขที่ ${paymentTransaction?.order_id?.delivery?.number} หมู่ที่ ${paymentTransaction?.order_id?.delivery?.village_number} ซอย ${paymentTransaction?.order_id?.delivery?.alley} ถนน ${paymentTransaction?.order_id?.delivery?.road} ตำบล ${paymentTransaction?.order_id?.delivery?.sub_district} อำเภอ ${paymentTransaction?.order_id?.delivery?.district} ตำบล ${paymentTransaction?.order_id?.delivery?.sub_district} จังหวัด ${paymentTransaction?.order_id?.delivery?.province}`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: `เบอร์โทร ${paymentTransaction?.order_id?.delivery?.tel}`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: `${paymentTransaction?.order_id?.delivery?.postcode}`,
                border: [false, false, false, false],
                style: 'name',
              },
            ],
          ],
        },
      },
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['*', '*', '*'],

          body: [
            [
              [
                {
                  text: `หมายเลขการจัดส่งสินค้า ( ${paymentTransaction?.order_id?.prefix} )`,
                  alignment: 'center',
                  border: [false, true, false, false],
                },
                {
                  text: `วันที่สั่งซื้อสินค้า ${
                    dayjs(new Date(paymentTransaction?.createdAt))
                      .locale('th')
                      .format('DD MMM BBBB HH:mm') || '-'
                  }`,
                  alignment: 'center',
                  border: [false, false, false, false],
                },
              ],

              [
                {
                  text: 'สั่งซื้อสินค้าอีกครั้งทาง',
                  border: [true, true, false, false],
                  alignment: 'center',
                },
                {
                  alignment: 'center',
                  image: 'QR',
                  width: 50,
                  margin: [0, 0, 0, 0],
                  border: [true, false, false, true],
                },
              ],

              [
                {
                  text: `จำหน่ายโดย ${setting?.owner?.name}`,
                  border: [true, true, false, false],
                  alignment: 'center',
                },
                // {
                //   image: 'logo',
                //   border: [true, true, false, false],
                //   rowSpand: 3,
                //   width: 70,
                //   margin: [0, 0, 0, 0],
                //   alignment: 'center',
                // },
                {
                  text: `${setting?.owner?.website}`,
                  alignment: 'center',
                  border: [true, false, false, false],
                },
              ],
            ],
          ],
        },
      },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },

      name: {
        fontSize: 16,
        bold: true,
      },
      tab: {
        color: 'white',
      },
    },
    images: {
      QR: genQR(setting?.owner?.website || '1234'),
      // logo: setting?.logo?.url || '-',
    },
  };
  pdfMake.createPdf(documentRef).open();
};
export default CoverPrint;
