import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  Customers,
  CustomerTypes,
  DetailCustomer,
  EditCustomer,
  EditCustomerType,
  Users,
  EditUser,
} from '../views/CRM';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function CRM() {
  const module = 'CRM';
  const prefix = '/crm';
  const name = 'ลูกค้าสัมพันธ์';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : CRM');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  // console.log(
  //   'me & availableModule',
  //   accessRight(me, module, 0, availableModule),
  // );

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="min-h-screen  pt-16 px-8 flex w-full">
          <MainSidebar
            onMobileClose={handleOnMobileNavClose}
            openMobile={isMobileNavOpen}
            me={me}
          />
          <div className="py-4 w-full">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/customers`} />
              <Route exact path={`${prefix}/customers`}>
                <Customers title="จัดการข้อมูลลูกค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/customer/:id`}>
                <DetailCustomer
                  title="รายละเอียดข้อมูลลูกค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/users`}>
                <Users title="จัดการผู้ใช้" subtitle={name} />
              </Route>
              {/**  สิทธิที่เพิ่มขึ้นมาสำหรับ User ที่เป็นผู้จัดการ */}
              {accessRight(me, module, 1, availableModule) && (
                <Switch>
                  <Route exact path={`${prefix}/customer/edit/:id`}>
                    <EditCustomer title="แก้ไขข้อมูลลูกค้า" subtitle={name} />
                  </Route>

                  <Route exact path={`${prefix}/user/edit/:id`}>
                    <EditUser title="แก้ไขผู้ใช้ลูกค้า" subtitle={name} />
                  </Route>

                  <Route exact path={`${prefix}/customer-types`}>
                    <CustomerTypes title="จัดการประเภทลูกค้า" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/customer-type/edit/:id`}>
                    <EditCustomerType
                      title="แก้ไขประเภทลูกค้า"
                      subtitle={name}
                    />
                  </Route>
                  <Route path="*">
                    <NotAuthorized />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotAuthorized />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default CRM;
