import {
  PAYMENT_TRANSACTION_ALL,
  PAYMENT_TRANSACTION_GET,
  PAYMENT_TRANSACTION_DEL,
  PAYMENT_TRANSACTION_PUT,
  PAYMENT_TRANSACTION_LOADING,
  PAYMENT_TRANSACTION_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const paymentTransactionAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      startDate = '',
      endDate = '',
      customerId = '',
      checkPaymentOrder = false,
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/paymentTransaction?name=${name}&size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}&customerId=${customerId}&checkPaymentOrder=${checkPaymentOrder}`,
    );
    if (status === 200) {
      dispatch({ type: PAYMENT_TRANSACTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const paymentTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/paymentTransaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PAYMENT_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const paymentTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/paymentTransaction/${id}`,
      payload,
    );
    dispatch({ type: PAYMENT_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const paymentTransactionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/paymentTransaction/${id}`,
    );
    dispatch({ type: PAYMENT_TRANSACTION_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
