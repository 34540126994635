import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function DepartmentForm({ errors, department, Controller, control }) {
  console.log('department', department);
  const renderAccessList = () => (
    <div className="px-2">
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.DASHBOARD'}
          control={control}
          defaultValue={department ? department.access?.DASHBOARD : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="แดชบอร์ด"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.POS'}
          control={control}
          defaultValue={department ? department.access?.POS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการขายหน้าร้าน"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.SPM'}
          control={control}
          defaultValue={department ? department.access?.SPM : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการคำสั่งซื้อ"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.WMS'}
          control={control}
          defaultValue={department ? department.access?.WMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="คลังสินค้า"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.HRMS'}
          control={control}
          defaultValue={department ? department.access?.HRMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ทรัพยากรบุคคล"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.CRM'}
          control={control}
          defaultValue={department ? department.access?.CRM : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ลูกค้าสัมพันธ์"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.CMS'}
          control={control}
          defaultValue={department ? department.access?.CMS : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการเว็บไซต์"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'access.DISCOUNT'}
          control={control}
          defaultValue={department ? department.access?.DISCOUNT : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="จัดการส่วนลด"
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={department ? department.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อแผนก"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={department ? department.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div> {renderAccessList()} </div>
    </div>
  );
}

DepartmentForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  department: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

DepartmentForm.defaultProps = {
  department: null,
};

export default DepartmentForm;
