import CustomerForm from './CRM/CustomerForm';
import EditCustomerForm from './CRM/EditCustomerForm';
import CustomerTypeForm from './CRM/CustomerTypeForm';

import DepartmentForm from './HRMS/DepartmentForm';
import RoleTypeForm from './HRMS/RoleTypeForm';
import EmployeeForm from './HRMS/EmployeeForm';
import UserForm from './HRMS/UserForm';

import MaterialTypeForm from './IMS/MaterialTypeForm';
import MaterialForm from './IMS/MaterialForm';
import MaterialTransactionTypeForm from './IMS/MaterialTransactionTypeForm';
import PlaceForm from './IMS/PlaceForm';
import MaterialStockForm from './IMS/MaterialStockForm';

import ProductTypeForm from './WMS/ProductTypeForm';
import SponsorForm from './CMS/SponsorForm';
import ProductForm from './WMS/ProductForm';
import BrandForm from './WMS/BrandForm';
import ProductTransactionTypeForm from './WMS/ProductTransactionTypeForm';
import WarehouseForm from './WMS/WarehouseForm';
import ProductStockForm from './WMS/ProductStockForm';
import ProductEditStockForm from './WMS/ProductEditStockForm';
import EditProductForm from './WMS/EditProductForm';
import EditOptionForm from './WMS/EditOptionForm';
import EditImageForm from './WMS/EditImageForm';

import ProcessTemplateForm from './MMS/ProcessTemplateForm';

import OrderStatusTypeForm from './SPM/OrderStatusTypeForm';
import OrderForm from './SPM/OrderForm';
import AddProductForm from './SPM/AddProductForm';
import ExportForm from './SPM/ExportForm';
import IncreaseProductForm from './SPM/IncreaseProductForm';

import MainSiteForm from './CMS/MainSiteForm';
import CouponForm from './DISCOUNT/CouponForm';

import InformationForm from './Setting/Information';
import AvailableModuleForm from './Setting/AvailableModuleForm';
import AddProviderForm from './Setting/AddProviderForm';
import SEOManagementForm from './Setting/SEOManagementForm';

export {
  AvailableModuleForm,
  InformationForm,
  CustomerForm,
  EditCustomerForm,
  CustomerTypeForm,
  DepartmentForm,
  EditProductForm,
  EditOptionForm,
  EditImageForm,
  RoleTypeForm,
  EmployeeForm,
  AddProductForm,
  UserForm,
  MaterialTypeForm,
  MaterialForm,
  MaterialTransactionTypeForm,
  PlaceForm,
  MaterialStockForm,
  ProductTypeForm,
  ProductForm,
  ProductTransactionTypeForm,
  WarehouseForm,
  ProductStockForm,
  OrderStatusTypeForm,
  ProcessTemplateForm,
  OrderForm,
  MainSiteForm,
  ProductEditStockForm,
  ExportForm,
  IncreaseProductForm,
  AddProviderForm,
  SEOManagementForm,
  BrandForm,
  SponsorForm,
  CouponForm,
};

export default {
  CustomerForm,
  EditCustomerForm,
  CustomerTypeForm,
  DepartmentForm,
  RoleTypeForm,
  EmployeeForm,
  AddProductForm,
  EditOptionForm,
  UserForm,
  EditProductForm,
  MaterialTypeForm,
  EditImageForm,
  MaterialForm,
  MaterialTransactionTypeForm,
  PlaceForm,
  MaterialStockForm,
  ProductTypeForm,
  ProductForm,
  ProductTransactionTypeForm,
  WarehouseForm,
  ProductStockForm,
  OrderStatusTypeForm,
  ProcessTemplateForm,
  OrderForm,
  MainSiteForm,
  ProductEditStockForm,
  ExportForm,
  IncreaseProductForm,
  AddProviderForm,
  SEOManagementForm,
  BrandForm,
  SponsorForm,
  CouponForm,
};
